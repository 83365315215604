<template>
    <section>
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <button class="button is-small is-rounded is-dark" @click="goToArea(author.area.moniker)">
                        <span class="icon is-small">
                            <i class="fas fa-arrow-left"></i> 
                        </span> 
                        <span>{{ author.area.name }}</span>
                    </button>
                </div> 
            </div>
        </div>
        <div class="level">            
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-size-2">{{ title }}</h1>
                </div>                
            </div>
            <div class="level-right">
                <div class="level-item">
                    <span v-if="author && author.disciplines">
                        <b-tag v-for="discipline in author.disciplines"
                            v-bind:key="discipline"  
                            type="is-light is-primary ml-2" rounded>{{discipline}}</b-tag>
                    </span>
                </div>               
            </div>                                                              
        </div>
        <div class="level">            
            <div class="level-left">
                <div class="level-item">
                    <h4 class="subtitle is-size-5 mr-2">                               
                        <b-icon icon="map-marker-alt"></b-icon> {{ shortAddress}}
                    </h4>
                </div>                                
            </div>                
        </div>          
    </section>            
</template>

<script>
export default {
    props: ['author'],
    name: 'AuthorHeader',
    methods: {
        goToArea(moniker) {
            this.$router.push(`/area/${moniker}`);
        }
    },
    computed: {
        shortAddress: {
            get() { 
                if(this.author && 
                    this.author.address && 
                    this.author.address.city && 
                    this.author.address.region &&
                    this.author.address.country) {  
                    return `${this.author.address.city}, ${this.author.address.region}, ${this.author.address.country}`; 
                }

                return 'Unknown location';
            }            
        },
        title: { 
            get() {
                if(this.author && this.author.name) { 
                    return this.author.name;
                }
                return 'Unknown';
            }   
        }
    }
}
</script>
<template>
  <main class="mt-5">
    <div class="container pr-3 pl-3">
      <h1 class="title">{{ $t('contact_us.title') }}</h1>
      <div class="columns">
        <div class="column is-6">
          <div v-if="messageSent" class="content">
            <div class="box has-background-success has-text-centered">   
              <img src="img/contact/paper-airplane.png" style="height:150px;margin:auto;" />              
            
            <h2 class="title">{{ $t('contact_us.message_sent') }}</h2>
            <p>{{ $t('contact_us.answering_promise') }}</p>
            </div>
            <b-button
                  expanded
                  icon-pack="fas"
                  icon-left="envelope"
                  type="is-primary"
                  @click="resetForm"
                  >{{ $t('contact_us.new_message') }}</b-button
                >                
          </div>
          <div v-else class="content">
            <Loader v-show="loading" />
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <ValidationProvider
                  name="Name"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field
                    :label="$t('contact_us.name')"
                    class="mb-4"
                    :type="errors[0] ? 'is-danger' : ''"
                    :message="errors[0]"
                  >
                    <b-input v-model="name"></b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  name="Email"
                  rules="required|email"
                  v-slot="{ errors }"
                >
                  <b-field
                    :label="$t('contact_us.email')"
                    class="mb-4"
                    :type="errors[0] ? 'is-danger' : ''"
                    :message="errors[0]"
                  >
                    <b-input v-model="email"></b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  name="Message"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <b-field
                    :label="$t('contact_us.message')"
                    class="mb-4"
                    :type="errors[0] ? 'is-danger' : ''"
                    :message="errors[0]"
                  >
                    <b-input
                      v-model="message"
                      maxlength="500"
                      type="textarea"
                      :disabled="loading"
                    ></b-input>
                  </b-field>
                </ValidationProvider>

                <ValidationProvider
                  name="AcceptTerms"
                  :rules="{ required: { allowFalse: false } }"
                  v-slot="{ errors }"
                >
                  <b-field>
                    <b-checkbox
                      class="mb-4 pb-4"
                      :type="errors[0] ? 'is-danger' : ''"
                      v-model="acceptTerms"
                    >
                      {{ $t("contact_us.terms_acceptance") }}
                      <a
                        href="javascript: return false;"
                        @click="
                          {
                            {
                              isPrivacyPolicyModalActive =
                                !isPrivacyPolicyModalActive;
                            }
                          }
                        "
                        >{{ $t("contact_us.terms_link") }}</a
                      >
                    </b-checkbox>
                  </b-field>
                </ValidationProvider>

                <b-button
                  :disabled="!!!acceptTerms"
                  expanded
                  icon-pack="fas"
                  icon-left="envelope"
                  type="is-primary"
                  native-type="submit"
                  >{{ $t("contact_us.send") }}</b-button
                >
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="isPrivacyPolicyModalActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="Example Modal"
      close-button-aria-label="Close"
      aria-modal
      :can-cancel="false"
    >
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Open My Art</p>
          <button
            type="button"
            class="delete"
            @click="isPrivacyPolicyModalActive = false"
          />
        </header>
        <section class="modal-card-body">
          <Privacy />
        </section>
        <footer class="modal-card-foot">
          <b-button
            :label="$t('actions.close')"
            @click="isPrivacyPolicyModalActive = false"
          />
        </footer>
      </div>
    </b-modal>
  </main>
</template>

<script>
import Privacy from "@/views/PrivacyView.vue";
import Loader from "@/components/Loader.vue";

export default {
  components: {
    Privacy,
    Loader,
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      acceptTerms: null,
      isPrivacyPolicyModalActive: false,
      loading: false,
      messageSent: false,
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      
      var payload = {};

      payload["name"] = this.name;
      payload["message"] = this.message;
      payload["emailAddress"] = this.email;

      // Post the payload to the contact endpoint.
      await fetch("/api/contact-us", {
        method: "post",
        body: JSON.stringify(payload),
      })
        .then((resp) => {
          if (!resp.ok) {
            this.$buefy.toast.open({
              position: "is-top",
              message: this.$i18n.t("contact_us.error"),
              type: "is-danger",
            });
            return;
          }

          this.messageSent = true;
        })
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.messageSent = false;
      this.name = '';
      this.message = '';
      this.email = '';
      this.acceptTerms = null;
    }
  },
};
</script>
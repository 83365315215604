<template>
  <main class="mt-5">
    <div class="container pr-3 pl-3">
      <div class="content">
        <LegalContentEs 
          v-if="locale === 'es'"
          :data="legalDetails"
          />
        <LegalContentEn 
          v-if="locale==='en'"
          :data="legalDetails" />
        <LegalContentFr 
          v-if="locale==='fr'"
          :data="legalDetails" />        
      </div>
    </div>
  </main>
</template>

<script>
  import LegalContentEs from '@/components/Legal/LegalContentEs.vue';
  import LegalContentEn from '@/components/Legal/LegalContentEn.vue';
  import LegalContentFr from '@/components/Legal/LegalContentFr.vue';
  export default {
    components: {
      LegalContentEs,
      LegalContentEn,
      LegalContentFr
    },  
    data() {
      return {
        legalDetails: {
          web: "https://openmy.art",
          owner: "F. Martinez",
          cif:"Y5695733G",
          registeredOffice: "Calle Esteban de la Foz, Monte, 39012, Santander, España",          
          email: "info@openmy.art"
        }        
      };
    },
    computed: {
      locale() {
        return this.$i18n.locale;
      }
    }
  };
</script>

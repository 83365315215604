<template>
  <p>
    Open my art est un réseau vivant d'auteurs, ouvert aux nouveaux studios qui
    veulent faire partie de ce guide des espaces créatifs. La naissance de ce
    projet découle de la nécessité de retrouver l'intérêt pour l'art et de
    rendre notre profession durable. Si vous êtes un auteur, si vous consacrez
    votre vie à être un auteur, si vous êtes passionné par la présentation de
    votre travail et de tout ce qui l'entoure. Si vous avez un espace qui peut
    être visité et que vous voulez le partager avec le public : Nous vous
    souhaitons la bienvenue à Open my Art !!!!
  </p>
</template>

<script>
export default {
  name: "FaqJoinContentFr",
};
</script>
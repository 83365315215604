<template>
    <nav>
        <b-navbar type="is-primary" spaced>
            <template #brand>
                <b-navbar-item tag="router-link" :to="{ path: '/' }">
                    <img
                    src="@/assets/img/white_logo.png"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                    >
                </b-navbar-item>
            </template>
            <template #end>
                <!-- <b-navbar-item tag="router-link" :to="{ path: '/about' }"> 
                    {{ $t('nav.about') }}
                </b-navbar-item> -->
                <b-navbar-item @click="switchLocale('es')">
                    <img
                        src="https://flagcdn.com/w20/es.png"
                        srcset="https://flagcdn.com/w40/es.png 2x"
                        width="20"
                        alt="Spanish"><span class="pl-2">ES</span>
                </b-navbar-item>
                <b-navbar-item @click="switchLocale('en')">
                    <img
                        src="https://flagcdn.com/w20/gb.png"
                        srcset="https://flagcdn.com/w40/gb.png 2x"
                        width="20"
                        alt="English"><span class="pl-2">EN</span>
                </b-navbar-item>
                <b-navbar-item @click="switchLocale('fr')">
                    <img
                        src="https://flagcdn.com/w20/fr.png"
                        srcset="https://flagcdn.com/w40/fr.png 2x"
                        width="20"
                        alt="French"><span class="pl-2">FR</span>
                </b-navbar-item>
                
            </template>
        </b-navbar>
    </nav>
</template>

<script>
import { localeChanged } from 'vee-validate'

export default {
    name: 'NavBar',
    data() {
        return {
            locale: 'es'
        }
    },
    methods: {
        switchLocale(selectedLocale) {
            if(this.$i18n.locale !== selectedLocale) {
                this.$i18n.locale = selectedLocale;
                localeChanged();
            }
        }
    }    
}
</script>
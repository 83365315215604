<template>
  <p>
    Open My Art is a living network of authors, open to new studios that want to
    be part of this guide of creative spaces. The birth of this project arises
    from the need to recover the interest in Art interest in art and make our
    profession sustainable. If you are an author, if you dedicate your life to
    being an author, if you are passionate about showing your work and
    everything that surrounds it. If you have a space that can be visited and
    you want to share it with the public: We welcome you to Open my Art!!!!
  </p>
</template>

<script>
export default {
  name: "FaqJoinContentEn",
};
</script>
<template>
  <main class="mt-5">
    <div class="container pr-3 pl-3">
    <div class="content">
      <CookiesPolicyEs v-if="locale === 'es'" :data="cookiesPolicyDetails" />
      <CookiesPolicyEn v-if="locale === 'en'" :data="cookiesPolicyDetails" />
      <CookiesPolicyFr v-if="locale === 'fr'" :data="cookiesPolicyDetails" />
    </div>
    </div>
  </main>
</template>

<script>
  import CookiesPolicyEs from '@/components/CookiesPolicy/CookiesPolicyEs.vue';
  import CookiesPolicyEn from '@/components/CookiesPolicy/CookiesPolicyEn.vue';
  import CookiesPolicyFr from '@/components/CookiesPolicy/CookiesPolicyFr.vue';
  export default {
  components: {
    CookiesPolicyEs,
    CookiesPolicyEn,
    CookiesPolicyFr
  },
  data() {
    return {
      cookiesPolicyDetails: {
        owner: "F. Martinez",
        web: "https://openmy.art"
      }
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  }  
};
</script>
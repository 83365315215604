<template>
    <div class="card">
        <div class="card-content">
            <div class="content" v-if="author && author.bio">
                <h5 class="is-title is-size-5">{{ $t('author.labels.bio') }}</h5>
                <p class="has-text-justified is-italic">"{{ author.bio }}"</p>
            </div>
            <div class="media">
                <div class="media-content has-text-centered">                                        
                    <h5 class="title is-size-5">{{ author.name }}</h5>                    
                    <figure class="image is-96x96 is-inline-block">
                        <img
                        :src="author.profilePictureUrl"
                        alt="Placeholder image"
                        style="border-radius: 50%"
                        />
                    </figure>
                </div>
            </div>                                       
        </div>
    </div>
</template>

<script>
export default {
    props: ['author'],
    name: 'AuthorBio'
}
</script>
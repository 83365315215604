<template>
<div class="mt-5">
        <div class="level">
          <h2 class="title">{{ $t('faq.title') }}</h2>
        </div>
        <FaqWhat />
        <FaqHow />        
        <FaqJoin />          
      </div>
</template>

<script>
import FaqWhat from '@/components/Home/Faq/FaqWhat.vue';
import FaqHow from '@/components/Home/Faq/FaqHow.vue';
import FaqJoin from '@/components/Home/Faq/FaqJoin.vue';
export default {
    name: 'Faq',
    components: {
        FaqWhat,
        FaqHow,
        FaqJoin
    }    
}
</script>
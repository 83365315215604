<template>
<div class="mt-5">
    <div class="level">
        <h2 class="title">{{ title }}</h2>
    </div>
    <div class="container">
        <div class="columns">
            <div v-for="area in areas" 
                        :key="area.name" 
                        :value="area" class="column is-3">
                        
                    <AreaCard :area="area" />
                    
                </div>
            </div>
    </div>
</div>
</template>

<script>
import AreaCard from '@/components/Home/AreaCard.vue';
import areasData from "@/assets/api/areas.json";

export default {
    name: 'AreasCarousel',
    props: ['title'],
    data() {
        return {
            areas: areasData
        };
    }, 
    components: {
        AreaCard
    }
}
</script>
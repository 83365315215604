<template>
  <section>
    <h1 class="title">{{ $t('legal.notice') }}</h1>
        <h3 class="title">1. BUT ET ACCEPTATION</h3>
        <p class="has-text-justified">Le présent avis juridique régit l'utilisation du site web {{data.web}} (ci-après, LE SITE WEB), propriété de {{data.owner}} (ci-après, LE PROPRIÉTAIRE DU SITE WEB).</p>
        <p class="has-text-justified">La navigation sur le site web du PROPRIÉTAIRE DU SITE WEB confère la condition d'utilisateur de ce dernier et implique l'acceptation pleine et sans réserve de toutes les dispositions incluses dans le présent avis légal, qui peut subir des modifications.</p>
        <p class="has-text-justified">L'utilisateur s'engage à faire un usage correct du site web, conformément aux lois, à la bonne foi, à l'ordre public, aux usages de la circulation et au présent avis juridique. L'utilisateur sera responsable envers le PROPRIÉTAIRE DU SITE WEB ou envers des tiers de tous les dommages qui pourraient être causés en raison d'une violation de cette obligation.</p>
        
        <h3 class="title">2. IDENTIFICATION</h3>
        <p class="has-text-justified">LE PROPRIÉTAIRE DU SITE WEB, conformément à la loi 34/2002, du 11 juillet, sur les services de la société de l'information et le commerce électronique, vous informe que :</p>        
        <ul>          
          <li class="has-text-justified">Son CIF/NIF/NIE est : {{data.cif}}</li>
          <li class="has-text-justified">Son siège social se trouve à :  {{data.registeredOffice}}</li>
        </ul>       

        <h3 class="title">3. COMMUNICATIONS</h3>
        <p class="has-text-justified">Toutes les notifications et communications entre les utilisateurs et le PROPRIÉTAIRE DU SITE WEB seront considérées comme effectives, à toutes fins utiles, lorsqu'elles seront effectuées par courrier ou par tout autre moyen détaillé ci-dessous :</p>
        <ul>
          <li class="has-text-justified">Courrier électronique : {{data.email}}</li>
        </ul>

        <h3 class="title">4. CONDITIONS D'ACCES ET UTILISATION</h3>
        <p class="has-text-justified">Le site web et ses services sont d'accès libre et gratuit, cependant, LE PROPRIÉTAIRE DU SITE WEB conditionne l'utilisation de certains des services offerts sur son site web à l'accomplissement préalable du formulaire correspondant.</p>
        <p class="has-text-justified">L'utilisateur garantit l'authenticité et l'actualité de toutes les données fournies au PROPRIÉTAIRE DU SITE WEB et sera seul responsable de toute déclaration fausse ou inexacte.</p>
        <p class="has-text-justified">L'utilisateur s'engage expressément à faire un usage approprié des contenus et services du PROPRIÉTAIRE DU SITE WEB et à ne pas les utiliser pour, entre autres :</p>
        <ol type="a">
          <li class="has-text-justified">Diffuser des contenus criminels, violents, pornographiques, racistes, xénophobes, offensants, faisant l'apologie du terrorisme ou, en général, contraires à la loi ou à l'ordre public.</li>
          <li class="has-text-justified">Introduire dans le réseau des virus informatiques ou réaliser des actions susceptibles d'altérer, d'endommager, d'interrompre ou de générer des erreurs ou des dommages aux documents électroniques, aux données ou aux systèmes physiques et logiques du PROPRIÉTAIRE DU SITE WEB ou de tiers ; ainsi que d'entraver l'accès d'autres utilisateurs au site web et à ses services par la consommation massive de ressources informatiques à travers lesquelles le PROPRIÉTAIRE DU SITE WEB fournit ses services.</li>
          <li class="has-text-justified">Tenter d'accéder aux comptes de courrier électronique d'autres utilisateurs ou à des zones à accès restreint des systèmes informatiques du PROPRIÉTAIRE DU SITE WEB ou de tiers et, le cas échéant, en extraire des informations.</li>
          <li class="has-text-justified">Violer les droits de propriété intellectuelle ou industrielle, ainsi que violer la confidentialité des informations du PROPRIÉTAIRE DU SITE ou de tiers.</li>
          <li class="has-text-justified">Usurper l'identité d'un autre utilisateur, des autorités publiques ou d'un tiers.</li>
          <li class="has-text-justified">La reproduction, la copie, la distribution, la mise à disposition ou toute autre forme de communication publique, la transformation ou la modification des contenus, sauf autorisation du titulaire des droits correspondants ou autorisation légale.</li>
          <li class="has-text-justified">Collecter des données à des fins publicitaires et envoyer des publicités de toute nature et des communications à des fins de vente ou à d'autres fins commerciales sans demande ou consentement préalable.</li>
        </ol>

        <h3 class="title">5. PROPRIÉTÉ INTELLECTUELLE ET INDUSTRIELLE</h3>
        <p class="has-text-justified">Tous les contenus du site web, tels que les textes, les photographies, les graphiques, les images, les icônes, la technologie, le logiciel, ainsi que sa conception graphique et ses codes sources, constituent une œuvre dont la propriété appartient au PROPRIÉTAIRE DU SITE WEB, et aucun des droits d'exploitation de celle-ci ne peut être compris comme étant cédé à l'utilisateur au-delà de ce qui est strictement nécessaire pour l'utilisation correcte du site web.</p>
        <p class="has-text-justified">En résumé, les utilisateurs qui accèdent à ce site web peuvent visualiser les contenus et réaliser, le cas échéant, des copies privées autorisées, à condition que les éléments reproduits ne soient pas ensuite cédés à des tiers, ni installés sur des serveurs connectés à des réseaux, ni soumis à un quelconque type d'exploitation.</p>
        <p class="has-text-justified">De même, toutes les marques, noms commerciaux ou signes distinctifs de toute sorte qui apparaissent sur le site web sont la propriété du PROPRIÉTAIRE DU SITE WEB, et il ne peut être compris que l'utilisation ou l'accès à ceux-ci attribue un quelconque droit sur ceux-ci à l'utilisateur.</p>
        <p class="has-text-justified">La distribution, la modification, le transfert ou la communication publique des contenus et tout autre acte qui n'a pas été expressément autorisé par le titulaire des droits d'exploitation sont interdits.</p>

        <h3 class="title">6. LIENS HYPER LIENS</h3>
        <p class="has-text-justified">L'établissement d'un hyperlien n'implique en aucun cas l'existence d'une relation entre LE PROPRIÉTAIRE DU SITE WEB et le propriétaire du site web dans lequel il est établi, ni l'acceptation et l'approbation par LE PROPRIÉTAIRE DU SITE WEB de ses contenus ou services. Les personnes qui ont l'intention d'établir un lien hypertexte doivent demander une autorisation écrite préalable au PROPRIÉTAIRE DU SITE. Dans tous les cas, l'hyperlien ne permettra que l'accès à la page d'accueil ou à la page d'accueil de notre site web, et devra également s'abstenir de faire des déclarations ou des indications fausses, inexactes ou incorrectes sur le PROPRIÉTAIRE DU SITE WEB, ou d'inclure des contenus illégaux, contraires aux bonnes coutumes et à l'ordre public.</p>
        <p class="has-text-justified">LE PROPRIÉTAIRE DU SITE WEB n'est pas responsable de l'utilisation que chaque utilisateur fait des matériaux mis à disposition sur ce site ni des actions réalisées sur la base de ces derniers.</p>
        <p class="has-text-justified">En ce qui concerne les hyperliens vers d'autres sites, comme c'est également le cas des réseaux sociaux, LE PROPRIÉTAIRE DU SITE WEB n'exerce aucun contrôle sur ces sites et leur contenu, n'assume aucune responsabilité quant au contenu de tout lien appartenant à d'autres sites web et ne garantit pas la disponibilité technique, la qualité, la fiabilité, l'exactitude, l'exhaustivité, la précision et la validité de tout matériel ou information contenu dans ces hyperliens ou autres sites Internet.</p>

        <h3 class="title">7. EXCLUSION DES GARANTIES ET DE LA RESPONSABILITÉ</h3>
        <p class="has-text-justified">Le contenu de ce site web est de nature générale et n'a qu'un caractère informatif. L'accès à l'ensemble du contenu n'est pas entièrement garanti, pas plus que son exhaustivité, son exactitude, sa validité ou son actualité, ni son adéquation ou son utilité pour un objectif spécifique.</p>
        <p class="has-text-justified">LE PROPRIÉTAIRE DU SITE WEB exclut, dans la mesure où la loi le permet, toute responsabilité pour les dommages de toute nature découlant de :</p>
        <ol type="a">
          <li class="has-text-justified">L'impossibilité d'accéder au site web ou le manque de véracité, d'exactitude, d'exhaustivité et/ou d'actualité des contenus, ainsi que l'existence de vices et de défauts de toute nature dans les contenus transmis, diffusés, stockés, mis à disposition, accessibles par le site web ou les services proposés.</li>
          <li class="has-text-justified">La présence de virus ou d'autres éléments dans les contenus qui peuvent provoquer des altérations dans les systèmes informatiques, les documents électroniques ou les données des utilisateurs.</li>
          <li class="has-text-justified">Le non-respect de la loi, de la bonne foi, de l'ordre public, des usages en matière de circulation et du présent avis juridique en raison d'une utilisation incorrecte du site web. En particulier, et à titre d'exemple, LE PROPRIÉTAIRE DU SITE WEB n'est pas responsable des actions de tiers qui violent les droits de propriété intellectuelle et industrielle, les secrets d'entreprise, les droits à l'honneur, l'intimité personnelle et familiale et l'image de soi, ainsi que la réglementation sur la concurrence déloyale et la publicité illégale.</li>
        </ol>
        <p class="has-text-justified">De même, LE PROPRIÉTAIRE DU SITE décline toute responsabilité pour les informations qui se trouvent en dehors de ce site et qui ne sont pas gérées directement par notre webmaster. La fonction des liens qui apparaissent sur ce site est exclusivement d'informer l'utilisateur de l'existence d'autres sources susceptibles d'enrichir le contenu proposé sur ce site. Le PROPRIÉTAIRE DU SITE WEB ne garantit pas et n'assume pas la responsabilité du fonctionnement ou de l'accessibilité des sites liés ; il ne suggère, n'invite ou ne recommande pas leur visite, et ne sera pas responsable des résultats obtenus. Le PROPRIÉTAIRE DU SITE WEB n'est pas responsable de l'établissement d'hyperliens par des tiers.</p>

        <h3 class="title">8. PROCÉDURE EN CAS D'ACTIVITÉS À CARACTÈRE ILLICITE</h3>
        <p class="has-text-justified">Si LE PROPRIÉTAIRE DU SITE WEB a connaissance de l'utilisation des services du site pour des activités portant atteinte aux droits ou constituant des actes illicites, il prendra les mesures nécessaires pour réprimer et éliminer ces activités, se réservant le droit d'engager des poursuites judiciaires.</p>
        <p class="has-text-justified">Dans le cas où un utilisateur ou un tiers considère qu'il existe des faits ou des circonstances qui révèlent le caractère illicite de l'utilisation de tout contenu et/ou de la réalisation de toute activité sur les pages web incluses ou accessibles à travers le site web, il devra envoyer une notification au PROPRIÉTAIRE DU SITE web en s'identifiant dûment, en précisant les infractions présumées.</p>

        <h3 class="title">9. PUBLICATIONS</h3>
        <p class="has-text-justified">Les informations administratives fournies par le site web ne remplacent pas la publication légale des lois, règlements, plans, dispositions générales et actes qui doivent être formellement publiés dans les journaux officiels des administrations publiques, qui sont le seul instrument qui atteste de leur authenticité et de leur contenu. Les informations disponibles sur ce site web doivent être comprises comme un guide n'ayant aucune valeur juridique.</p>

        <h3 class="title">10. LOI ET JURIDICTION APPLICABLE</h3>
        <p>Le présent avis juridique est régi par le droit espagnol. Les parties acceptent de se soumettre à la juridiction des Cours et Tribunaux qui leur correspondent selon la législation procédurale applicable.</p>
  </section>
</template>

<script>
export default {
    name: 'LegalContentFr',
    props: ['data']
}
</script>

<style>

</style>
<template>
  <p>
    Open my art es una red viva de autores y autoras, abierta a nuevos estudios
    que quieran formar parte de esta guía de espacios creativos. El nacimiento
    de este proyecto surge a partir de la necesidad de recuperar el Interés por
    el Arte y hacer sostenible nuestra profesión. Si eres autor o autora,
    dedicas tu vida a serlo, te apasiona mostrar tu trabajo y todo lo que hay
    alrededor de él. Si tienes un espacio que se pueda visitar y quieres
    compartirlo con el público: Te damos la Bienvenida a Open my Art!!!
  </p>
</template>

<script>
export default {
  name: "FaqJoinContentEs",
};
</script>
<template>
  <section>
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <button class="button is-small is-rounded is-dark" @click="goToHome()">
              <span class="icon is-small">
                  <i class="fas fa-arrow-left"></i> 
              </span> 
              <span>{{ $t('area.back_to_home') }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="level">
      <div class="level-left is-flex-direction-column is-align-items-flex-start">
        <div class="level-item">
          <h1 class="title is-size-3">
            <b-icon icon="map-marker-alt" class="mr-2"></b-icon>{{ area.name }}
          </h1>          
        </div>        
      </div>
      <div class="level-right">
        <div class="level-item pt-3 mt-3">
          <b-tag type="is-primary is-light" size="is-medium">{{ subtitle }}</b-tag>          
        </div>
      </div>     
    </div>    
    <div class="level mb-3 mobile-visible">
      <div class="level-left">
        <div class="level-item">
          <b-button
            v-if="showMapButtonIsVisible"
            rounded
            type="is-dark"
            icon-left="map"
            icon-pack="fas"
            @click="showMapButton"
            >{{ $t("area.map") }}</b-button
          >
          <b-button
            v-else
            rounded
            type="is-dark"
            icon-left="list"
            icon-pack="fas"
            @click="hideMapButton"
            >{{ $t("area.results") }}</b-button
          >
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AreaHeader",
  props: ["numberOfAuthors", "area"],
  data() {
    return {
      showMapButtonIsVisible: true,
    };
  },
  methods: {
    showMapButton: function () {
      this.showMapButtonIsVisible = !this.showMapButtonIsVisible;
      this.$emit("showMap");
    },
    hideMapButton: function () {
      this.showMapButtonIsVisible = !this.showMapButtonIsVisible;
      this.$emit("hideMap");
    },
    goToHome() {
      this.$router.push(`/`);
    }
  },
  computed: {
    subtitle: function() {
      const message = 
        this.numberOfAuthors > 1 ? 
        this.$i18n.t('area.subtitle_message_plural') :
        this.$i18n.t('area.subtitle_message_singular');

      return `${this.numberOfAuthors} ${message} ${this.area.name.split(',')[0]}`;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
.mobile-visible {
  @include mobile {
    display: block;
  }
  @include tablet {
    display: none;
  }
}
</style>
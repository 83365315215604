<template>
    <section v-if="author && author.address && author.languages">
        <h4 class="title is-size-4">{{ $t('visit.must_know') }}</h4>
            <div id="things-to-know" class="columns">
              <div class="column is-6 content pr-6">
                <p class="has-text-weight-bold">{{ $t('visit.hours') }}</p>
                <p>
                  <b-icon pack="fas" icon="clock" size="is-medium"></b-icon>
                  {{ author.visit.schedule }}
                </p>
                <p class="has-text-weight-bold">
                  {{ author.visit.offer.question }}
                </p>
                <p class="has-text-justified">
                  {{ author.visit.offer.answer }}
                </p>
                <p class="has-text-weight-bold">
                  {{ author.visit.booking.question }}
                </p>
                <p class="has-text-justified">
                  {{ author.visit.booking.answer }}
                </p>
              </div>
              <div class="column is-6 content">
                <p class="has-text-weight-bold">{{ $t('visit.languages') }}</p>
                <p class="has-text-justified">
                  <b-icon pack="fas" icon="language" size="is-medium"></b-icon>
                  {{ languagesForDisplay }}
                </p>
                <div v-html="author.visit.extra">
                </div>
              </div>
            </div>
    </section>
</template>

<script>
export default {
    props: ['author'],
    name: 'AuthorInfo',
    computed: {
        languagesForDisplay: {
            get() { 
                return this.author.languages.join(", ");
            }            
        }
    } 
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
#things-to-know .column {
    
  @include mobile{
    margin-bottom: 0;
    margin-top: 0;
  }
}
</style>
<template>
<div class="mt-5">
    <div class="level">
        <h2 class="title">{{ $t('home.featured_authors.title') }}</h2>
    </div>
    <div class="container">
        <div class="columns">
            <div v-for="author in authors" 
                        :key="author.name" 
                        :value="author" class="column is-3">
                        
                    <FeaturedAuthorCard 
                            :author="author" />
                    
                </div>
            </div>
    </div>
</div>
</template>
<script>
import FeaturedAuthorCard from '@/components/Home/FeaturedAuthorCard.vue';
import featuredAuthors from '@/assets/api/featured_authors.json';

export default {
    name: 'FeaturedAuthorsCarousel',
    data() {
        return {
            authors: featuredAuthors
        }
    },
    components: {
        FeaturedAuthorCard
    }
}
</script>
<template>
  <div class="card" style="width:100%; cursor:pointer;" @click="goToAuthor(moniker)">
    <div class="card-image">
      <figure class="image is-4by3">
        <img
          :src="image"
          :alt="name"
        />
      </figure>
    </div>
    <div class="card-content">
        <div class="content">
            <h3 class="title">{{ name }}</h3>
            <h5 class="subtitle">{{ status }}</h5>      
        </div>      
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoWindowContent",
  props: ["name","image","status", "moniker"],
  methods: {
    goToAuthor: function(moniker){
        this.$router.push(`/author/${moniker}`);
    }
  }
};
</script>

<style>
</style>
<template>
  <section>
    <h1 class="title">{{ $t('legal.cookies') }}</h1>

    <h3 class="title">1. DÉFINITION ET FONCTION GÉNÉRIQUE DES COOKIES.</h3>
    <p class="has-text-justified">{{data.owner}}, le propriétaire de ce site web vous informe que des cookies et/ou des technologies similaires sont utilisés pour stocker et récupérer des informations lorsque vous naviguez. En général, ces technologies peuvent être utilisées à des fins très diverses, comme, par exemple, vous reconnaître en tant qu'utilisateur, obtenir des informations sur vos habitudes de navigation ou personnaliser la manière dont le contenu est affiché. Les utilisations spécifiques que nous faisons de ces technologies sont décrites ci-dessous.</p>
    
    <h3 class="title">2. DES INFORMATIONS SUR LE TYPE DE COOKIES UTILISÉS ET LEUR FINALITÉ.</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Techniques :</span> Ils sont nécessaires à la navigation et au bon fonctionnement de notre site web. Ils permettent, par exemple, de contrôler le trafic et la communication des données, d'accéder à des zones restreintes, d'effectuer le processus d'achat d'une commande, d'utiliser des éléments de sécurité, de stocker du contenu afin de diffuser des vidéos ou de partager du contenu via les réseaux sociaux.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Personnalisation :</span> Celles-ci permettent à l'utilisateur d'accéder au service avec des caractéristiques prédéfinies en fonction d'une série de critères, tels que la langue, le type de navigateur utilisé pour accéder au service, la configuration régionale depuis laquelle on accède au service, etc.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Analyse :</span> sont celles qui, traitées par nous ou par des tiers, nous permettent de quantifier le nombre d'utilisateurs et d'effectuer ainsi la mesure et l'analyse statistique de l'utilisation faite par les utilisateurs du service offert. Pour ce faire, votre navigation sur notre site est analysée afin d'améliorer la gamme de produits ou services que nous proposons.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Publicité :</span> Ce sont celles qui permettent de gérer, de la manière la plus efficace possible, les espaces publicitaires qui peuvent être inclus sur notre site web.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Publicité comportementale :</span> sont celles qui, traitées par nous ou par des tiers, nous permettent d'analyser vos habitudes de navigation sur Internet afin de vous présenter des publicités liées à votre profil de navigation.
      </li>
    </ul>
    
    <h3 class="title">3. L'IDENTIFICATION DE CEUX QUI UTILISENT LES COOKIES :</h3>
    <p class="has-text-justified">Les informations obtenues par les cookies sont traitées uniquement par notre éditeur.</p>

    <h3 class="title">4. PÉRIODE DE RÉTENTION</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Cookies de session :</span> Il s'agit de cookies temporaires qui restent dans le fichier de cookies de votre navigateur jusqu'à ce que l'utilisateur quitte le site web, de sorte qu'aucun n'est enregistré sur le disque dur de votre ordinateur. Les informations obtenues grâce à ces cookies sont utilisées pour analyser les schémas de trafic sur le site web. À long terme, cela nous permet de fournir une meilleure expérience pour améliorer le contenu et faciliter son utilisation.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Cookies persistants :</span> sont stockées sur le disque dur et notre site web les lit à chaque nouvelle visite de l'utilisateur. Un cookie permanent a une date d'expiration spécifique. Le cookie cessera de fonctionner après cette date. Nous utilisons généralement ces cookies pour faciliter les services d'achat et d'enregistrement.
      </li>
    </ul>
    <p class="has-text-justified">Les données obtenues à partir de ces deux types de cookies seront conservées conformément à la politique de confidentialité de notre site web : {{data.web}}</p>

    <p class="has-text-justified has-text-weight-bold">Nous utilisons les cookies de tiers suivants :</p>

    <table class="table is-bordered">      
      <thead>
        <tr>
          <th>Nom</th>
          <th>Type</th>
          <th>Origine</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>cookieyes-consent</td>
          <td>Nécessaire</td>
          <td>CookieYes</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">CookieYes place ce témoin pour mémoriser les préférences des utilisateurs en matière de consentement afin que leurs préférences soient respectées lors des visites ultérieures de ce site. Il ne collecte ni ne stocke aucune information personnelle sur les visiteurs du site.</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Analytique</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics utilise ce cookie pour calculer les données relatives aux visiteurs, aux sessions et aux campagnes et suivre l’utilisation du site pour le rapport d’analyse du site. Le témoin stocke les informations de manière anonyme et attribue un numéro généré de manière aléatoire pour reconnaître les visiteurs uniques.</td>
        </tr>
        <tr>
          <td>_ga_*</td>
          <td>Analytique</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics utilise ce cookie pour stocker et compter les pages vues.</td>
        </tr>
      </tbody>
    </table>

    <p class="has-text-centered">
      <a href="#" class="cky-banner-element">PANNEAU DE CONFIGURATION DES COOKIES</a>
    </p>

    <h3 class="title">5. ACTIVATION, RESTRICTION ET/OU DÉSACTIVATION DES COOKIES</h3>
    <p class="has-text-justified">Vous pouvez autoriser ou bloquer les cookies, ainsi que supprimer vos données de navigation (y compris les cookies) du navigateur que vous utilisez. Veuillez vous référer aux options et instructions fournies par votre navigateur pour ce faire. Veuillez noter que si vous acceptez les cookies de tiers, vous devrez les supprimer des options de votre navigateur.</p>

    <h3 class="title">6. LES TRANSFERTS DE DONNÉES VERS DES PAYS TIERS PAR L'ÉDITEUR.</h3>
    <p class="has-text-justified">Nous ne transférons pas de données vers des pays tiers.</p>

    <h3 class="title">7. PROFILAGE</h3>
    <p class="has-text-justified">Nous ne faisons pas de profilage.</p>

    <h3 class="title">8. AUTRES INFORMATIONS</h3>
    <p class="has-text-justified">En ce qui concerne le reste des informations requises par l'article 13 RGPD, vous pouvez les consulter ici <router-link to="/privacy">{{ $t('legal.privacy') }}</router-link>.</p>

  </section>
</template>

<script>
export default {
    name: 'CookiesPolicyFr',
    props: ['data']
}
</script>

<style>

</style>
<template>
    <figure class="image is-16by9 mt-5" v-if="author && author.featuredVideoUrl">
        <iframe
        class="has-ratio"
        width="640"
        height="360"
        :src="author.featuredVideoUrl"
        frameborder="0"
        allowfullscreen
        ></iframe>
    </figure>
</template>

<script>
export default {
    props: ['author'],
    name: 'AuthorVideo'    
}
</script>
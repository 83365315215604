<template>
  <main class="mt-5">
    <section v-if="area" class="container pr-3 pl-3">                       
        <div class="columns">
            <div class="column">
                <AreaHeader 
                    @showMap="onShowMap" 
                    @hideMap="onHideMap"
                    :numberOfAuthors="area.authors.length"
                    :area="area"/>
            </div>            
        </div>
        <div class="columns is-desktop">
            <div class="column is-full-tablet is-half-desktop vertical-scrollable" v-show="!!!isMobile || (isMobile && !!!showMap)">                
                <AuthorThumbnail v-for="author in area.authors" :key="author.moniker" :value="author" :author="author" />                                       
            </div>
            <div class="column is-full-tablet is-half-desktop" v-show="!!!isMobile || (isMobile && showMap)">                
                <AreaResultsGoogleMap :markers="getMarkers()" :coordinates="area.coordinates" />
            </div>
        </div>       
    </section>
  </main>
</template>

<script>
import fetch from 'node-fetch';
import AreaHeader from '@/components/Area/AreaHeader.vue';
import AuthorThumbnail from '@/components/Area/AuthorThumbnail.vue';
import AreaResultsGoogleMap from '@/components/Area/AreaResultsGoogleMap.vue';

export default {
    components: {
        AreaHeader,
        AuthorThumbnail,
        AreaResultsGoogleMap
    },
    data() {
        return {
            area: null,            
            showMap: null,
            isMobile: window.matchMedia("only screen and (max-width: 860px)").matches
        };
    },
    async mounted() {
        var moniker = this.$route.params.moniker;
        await fetch(`./mock-api/${moniker}.json`)
        .then(response => {          
            return response.json();
        })
        .then(jsondata => {
            this.area = jsondata;
        });
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        getMarkers() {
            var markers = [];
            if(this.area.authors) {
                this.area.authors.forEach((author) => {
                    const marker = {
                        lat: author.coordinates.latitude,
                        lng: author.coordinates.longitude,                        
                    };
                    markers.push(
                        {
                            position: marker, 
                            name: author.name, 
                            image: author.image, 
                            status: author.status,
                            moniker: author.moniker 
                        });
                })
            }
            return markers;
        },
        onShowMap: function(){
            this.showMap = true;    
        },
        onHideMap: function(){
            this.showMap = false;
        },
        myEventHandler() {
            this.isMobile = window.matchMedia("only screen and (max-width: 860px)").matches;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.vertical-scrollable {
  @include tablet {
    max-height: 90vh;
    overflow-y: auto;
    cursor: pointer;
  }
}
</style>
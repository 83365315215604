<template>
    <div class="box is-flex mb-5 author-thumbnail" @click="goToAuthor(author.moniker)">                    
        <img width="50%" :src="author.image" />
        <div class="is-flex 
                    is-flex-direction-column
                    is-flex-wrap 
                    is-align-items-self-end
                    ml-4"
                    style="width:100%">
        <h2 class="title has-text-right is-size-4">{{ author.name }}</h2>
        <h6 class="subtitle is-size-4">{{ author.status }}</h6>                 
        </div>
    </div>
</template>

<script>
export default {
    name: 'AuthorThumbnail',
    props: ['author'],
    methods: {
        goToAuthor(moniker) {
            this.$router.push(`/author/${moniker}`);
        }
    }
}
</script>

<style lang="scss" scoped>
.author-thumbnail {
    cursor: pointer;
}

</style>

<template>
    <section v-if="author && author.address">
        <h4 class="title is-size-4">{{ $t('author.labels.location') }}</h4>
                <div class="pb-4">
                <b-icon
                    size="is-medium"
                    pack="fas"
                    icon="map-marker-alt"
                ></b-icon>
                {{ addressForDisplay }}
                </div>
                <AuthorGoogleMap :coordinates="author.address.coordinates" />
    </section>
</template>

<script>
import AuthorGoogleMap from '@/components/Author/AuthorGoogleMap.vue';

export default {    
    props: ['author'],
    name: 'AuthorLocation',
    components: {
        AuthorGoogleMap
    },
    computed: {
        addressForDisplay: {
            get() {
                return `${this.author.address.line1}, ${this.author.address.postcode}, ${this.author.address.city}, ${this.author.address.region}, ${this.author.address.country}`;
            }
        }
    }
}

</script>
<template>
  <section>
    <p>
      Pouvez-vous imaginer pouvoir partager une conversation avec les maîtres et
      maîtresses de l'histoire de l'art ? Aujourd'hui, on ne peut que regarder
      leurs œuvres dans les musées et imaginer ce qu'ils auraient pu être, car
      ils ont laissé leur corps derrière eux.
    </p>
    <p>
      Open My Art vous permettra de choisir des artistes vivants, très spéciaux,
      singuliers, uniques, auprès desquels vous pourrez prendre rendez-vous pour
      visiter leur espace créatif, leur cœur. Une occasion unique de ressentir
      la passion, les motivations, les sentiments, les émotions, les processus,
      les matériaux, le grand univers créatif interne des auteurs qui, une fois
      morts, seront les maîtres des grands musées du monde.
    </p>
  </section>
</template>

<script>
export default {
  name: "FaqWhatContentFr",
};
</script>
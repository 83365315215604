<template>
  <section>
      <NavBar/>
      <div id="app">
        <router-view/>
      </div>
      <Footer/>
  </section>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    NavBar,
    Footer
  }  
}
</script>

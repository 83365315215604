<template>
  <GmapMap
    :center="center(coordinates)"
    :zoom="9"
    :options="options"
    class="map">
    <GmapInfoWindow 
      :options="infoOptions" 
      :position="infoPosition" 
      :opened="infoOpened" 
      @closeclick="infoOpened=false">
      <InfoWindowContent 
        v-if="infoContent" 
        :name="infoContent.name" 
        :image="infoContent.image"
        :status="infoContent.status"
        :moniker="infoContent.moniker" />
    </GmapInfoWindow>
    <GmapMarker
      v-for="(item, index) in markers"
      :key="index"
      :position="item.position"
      :clickable="true"
      @click="toggleInfo(item, index)"
    />
  </GmapMap>
</template>

<script>
import InfoWindowContent from '@/components/Area/InfoWindowContent.vue'
export default {
  name: "AreaResultsGoogleMap",
  props: ["markers", "coordinates"],
  components: {
    InfoWindowContent,
  },
  data() {
    return {
      options: {
        streetViewControl: false,
        mapTypeControl: false,
      },
      infoPosition: null,
      infoContent: null,
      infoOpened: false,
      infoCurrentKey: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },
  methods: {
    center(coordinates) {
      return {
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      };
    },
    getPosition: function (marker) {
      return {
        lat: parseFloat(marker.position.lat),
        lng: parseFloat(marker.position.lng),
      };
    },
    toggleInfo: function (marker, key) {
      this.infoPosition = this.getPosition(marker);
      this.infoContent = {
       name: marker.name,
       image: marker.image,
       status: marker.status,
       moniker: marker.moniker 
      };
      if (this.infoCurrentKey == key) {
        this.infoOpened = !this.infoOpened;
      } else {
        this.infoOpened = true;
        this.infoCurrentKey = key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";

.map {
  
  width: 100%;
  height: 500px;
  min-height: 500px;
  
  @include desktop {
    height: 100%;
  }
}

</style>
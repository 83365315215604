<template>
  <div v-if="messageSent">
    <div class="modal-card">
      <header class="modal-card-head has-background-success">
        <img src="img/contact/paper-airplane.png" style="height:150px;margin:auto;" />
      </header>
      <section class="modal-card-body has-text-centered">
        <h3 class="subtitle">{{ $t('author.contact.message_sent') }}</h3>
        <p>
          {{ $t('author.contact.asap') }}
        </p>
      </section>
      <footer class="modal-card-foot is-flex-direction-column">
        <div class="is-flex">
          <b-button class="is-primary" :label="$t('author.contact.acceptText')" @click="$emit('close')" />              
        </div>
      </footer>
    </div>
  </div>
  <div v-else>
    <Loader v-if="loading" />
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(onSubmit)">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Contacta el artista</p>
            <button type="button" class="delete" @click="$emit('close')" />
          </header>
          <section class="modal-card-body">
            <ValidationProvider
              name="Name"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :label="$t('contact_us.name')"
                class="mb-4"
                :type="errors[0] ? 'is-danger' : ''"
                :message="errors[0]"
              >
                <b-input v-model="name"></b-input>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              name="Email"
              rules="required|email"
              v-slot="{ errors }"
            >
              <b-field
                :label="$t('contact_us.email')"
                class="mb-4"
                :type="errors[0] ? 'is-danger' : ''"
                :message="errors[0]"
              >
                <b-input v-model="email"></b-input>
              </b-field>
            </ValidationProvider>

            <ValidationProvider
              name="Message"
              rules="required"
              v-slot="{ errors }"
            >
              <b-field
                :label="$t('contact_us.message')"
                class="mb-4"
                :type="errors[0] ? 'is-danger' : ''"
                :message="errors[0]"
              >
                <b-input
                  v-model="message"
                  maxlength="500"
                  type="textarea"
                ></b-input>
              </b-field>
            </ValidationProvider>
          </section>
          <footer class="modal-card-foot is-flex-direction-column">
            <div class="is-flex">
              <ValidationProvider
                name="AcceptTerms"
                :rules="{ required: { allowFalse: false } }"
                v-slot="{ errors }"
              >
                <b-field>
                  <b-checkbox
                    class="mb-4 pb-4"
                    :type="errors[0] ? 'is-danger' : ''"
                    v-model="acceptTerms"
                  >
                    {{ $t("contact_us.terms_acceptance") }}
                    <a
                      href="./#/privacy"
                      target="_blank"
                      @click="
                        {
                          {
                            isPrivacyPolicyModalActive =
                              !isPrivacyPolicyModalActive;
                          }
                        }
                      "
                      >{{ $t("contact_us.terms_link") }}</a
                    >
                  </b-checkbox>
                </b-field>
              </ValidationProvider>
            </div>
            <div class="is-flex">
              <b-button label="Cancel" @click="$emit('close')" />
              <b-button
                :disabled="!!!acceptTerms"
                icon-pack="fas"
                icon-left="envelope"
                type="is-primary"
                native-type="submit"
                >{{ $t("contact_us.send") }}</b-button
              >
            </div>
          </footer>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';

export default {
  components: {
    Loader
  },
  data() {
    return {
      name: "",
      email: "",
      message: "",
      acceptTerms: false,
      messageSent: false,
      loading: false
    };
  },
  methods: {
    async onSubmit() {
      this.loading = true;

      var payload = {};

      payload["senderName"] = this.name;
      payload["authorName"] = this.$route.params.moniker;
      payload["senderEmail"] = this.email; 
      payload["senderMessage"] = this.message;

      console.log(payload);

      // Post the payload to the contact endpoint.
      await fetch("/api/contact-author", {
        method: "post",
        body: JSON.stringify(payload),
      })
        .then((resp) => {
          if (!resp.ok) {
            this.$buefy.toast.open({
              position: "is-top",
              message: this.$i18n.t("contact_us.error"),
              type: "is-danger",
            });
            return;
          }
          this.messageSent = true;
        })
        .finally(() => (this.loading = false));
      // this.$emit("close");
      // this.$buefy.toast.open({
      //   position: "is-top",
      //   message: `${this.$i18n.t("contact_us.message_sent")}`,
      //   type: "is-success",
      // });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
.modal-card {
  @include mobile {
    width: auto;
  }
  @include desktop {
    width: 600px;
  }
}
</style>
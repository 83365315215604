<template>
    <section class="hero is-halfheight">
      <div class="hero-body has-background-primary-light">
        <div class="container has-text-centered">
          <figure>
            <img
            src="@/assets/img/Imagotipo 3@0.5x.png"
            alt="The Open My Art Logo"
            />
          </figure>
          <h3 class="subtitle is-size-4-mobile is-size-3">
            {{ $t('home.motto') }}
          </h3>
          <p>{{ $t('home.presentation') }}</p>
          <p>{{ $t('home.invitation') }}</p>
        </div>
      </div>
    </section>
</template>

<script>
export default {
    name: 'HomeHero'
}
</script>
<template>
    <div v-if="author" class="columns is-desktop is-vcentered">
        <div class="column is-one-third-desktop phone-and-website-column">
                <div><a :href=" 'tel:' + author.contact.phone "><b-icon
                    size="is-medium"
                    pack="fas"
                    icon="phone"
                    type="is-success"
                    ></b-icon>
                    {{ author.contact.phone }}</a></div>
                    <div>
                        <b-button
                    v-if="author.contact.website"
                    type="is-ghost"
                    icon-left="globe"
                    icon-pack="fas"
                    tag="a"
                    :href="author.contact.website"
                    target="_blank"
                    >{{ author.contact.website }}
                </b-button>
                    </div>
                    
                                                    
            </div>
        <div class="column is-one-third-desktop social-links">
            <div class="buttons is-justify-content-center">
                <b-button
                rounded
                outlined
                v-if="author.social.twitter"
                type="is-link"
                icon-left="x-twitter"
                icon-pack="fab"
                size="is-medium"
                tag="a"
                :href="author.social.twitter"
                target="_blank"
                style="border:none;border-radius:50%;"
                ></b-button>
                <b-button
                    outlined
                    rounded
                    v-if="author.social.youtube"
                    type="is-link"
                    icon-left="youtube"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.youtube"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
                    <b-button
                    outlined
                    rounded
                    v-if="author.social.facebook"
                    type="is-link"
                    icon-left="facebook-f"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.facebook"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
                    <b-button
                    outlined
                    rounded
                    v-if="author.social.instagram"
                    type="is-link"
                    icon-left="instagram"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.instagram"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
                    <b-button
                    outlined
                    rounded
                    v-if="author.social.spotify"
                    type="is-link"
                    icon-left="spotify"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.spotify"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
            </div>            
        </div>
        <div class="column is-one-third-desktop contact-button">
                <b-button
                    type="is-primary" 
                    size="is-medium"
                    @click="isContactFormActive = true"                         
                    >{{ $t('author.labels.contact') }}</b-button
                >
            </div>

        <b-modal
            id="contactFormModal"
            v-model="isContactFormActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-label="Contact"
            close-button-aria-label="Close"
            aria-modal
            :can-cancel="false"
            >
            <template #default="props">
                <AuthorContactForm @close="props.close"></AuthorContactForm>
            </template>
    </b-modal>

    </div>
    <!-- </div> -->
    <!-- <div v-if="author" class="level" style="width:100%">
        <div class="level-left">            
            <div class="level-item">
                <b-icon
                    size="is-medium"
                    pack="fas"
                    icon="phone"
                    type="is-success"
                    ></b-icon>
                    {{ author.contact.phone }}
            </div>    
            <div class="level-item">
                <b-button
                    v-if="author.contact.website"
                    type="is-ghost"
                    icon-left="globe"
                    icon-pack="fas"
                    tag="a"
                    :href="author.contact.website"
                    target="_blank"
                    >{{ author.contact.website }}
                </b-button>
            </div>                        
        </div>
        <div class="level-right">
            <div class="level-item">                
                <b-button
                rounded
                outlined
                v-if="author.social.twitter"
                type="is-link"
                icon-left="twitter"
                icon-pack="fab"
                size="is-medium"
                tag="a"
                :href="author.social.twitter"
                target="_blank"
                style="border:none;border-radius:50%;"
                ></b-button>
            </div>
            <div class="level-item">
                <b-button
                    outlined
                    rounded
                    v-if="author.social.youtube"
                    type="is-link"
                    icon-left="youtube"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.youtube"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
            </div>
            <div class="level-item">
                <b-button
                    outlined
                    rounded
                    v-if="author.social.facebook"
                    type="is-link"
                    icon-left="facebook"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.facebook"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
            </div>
                <div class="level-item">
                    <b-button
                    outlined
                    rounded
                    v-if="author.social.instagram"
                    type="is-link"
                    icon-left="instagram"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.instagram"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
                    </div>
                    <div class="level-item">
                    <b-button
                    outlined
                    rounded
                    v-if="author.social.spotify"
                    type="is-link"
                    icon-left="spotify"
                    icon-pack="fab"
                    size="is-medium"
                    tag="a"
                    :href="author.social.spotify"
                    target="_blank"
                    style="border:none;border-radius:50%;"
                    ></b-button>
                        </div>                   
                
            <div class="level-item">
                <b-button
                    type="is-primary" 
                    size="is-medium"
                    @click="isContactFormActive = true"                         
                    >{{ $t('author.labels.contact') }}</b-button
                >
            </div>
        </div>

        <b-modal
            id="contactFormModal"
            v-model="isContactFormActive"
            has-modal-card
            trap-focus
            :destroy-on-hide="true"
            aria-role="dialog"
            aria-label="Contact"
            close-button-aria-label="Close"
            aria-modal
            :can-cancel="false"
            >
            <template #default="props">
                <AuthorContactForm @close="props.close"></AuthorContactForm>
            </template>
    </b-modal>

    </div> -->
</template>

<script>
import AuthorContactForm from '@/components/Author/AuthorContactForm.vue';

export default {
    props: ['author'],
    name: 'AuthorLinksBanner',
    components: {
        AuthorContactForm
    },
    data() {
        return {
            isContactFormActive: false
        }               
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
.phone-and-website-column {    
    text-align: center !important;

    @include desktop {
        text-align: left !important;
    }
}

.social-links {
    text-align: center !important;
}

.contact-button {
    text-align: center !important;

    @include desktop {
        text-align: right !important;
    }
}

</style>
<template>
  <p>
    Busca la provincia, elige el autor o autora que te encantaría conocer para
    sumergirte en su universo creativo. Solicita una cita. Recibirás un email
    para confirmar la reserva. ¿Fácil?
  </p>
</template>

<script>
export default {
  name: "FaqHowContentEs",
};
</script>
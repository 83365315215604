<template>
  <section>
    <p>
      Can you imagine being able to share a conversation with the masters of art
      history? Now you can only see their works in museums and imagine how they
      could have been, because they have already left their bodies.
    </p>
    <p>
      Open My Art will allow you to choose living artists, very special,
      singular, unique, from whom you will be able to make an appointment to
      visit their creative space, their heart. A unique opportunity to feel the
      passion, the motivations, the feelings, the emotions, the processes, the
      materials, the great internal creative universe of the authors who will be
      the masters in the great museums of the future, when they are dead.
    </p>
  </section>
</template>

<script>
export default {
  name: "FaqWhatContentEn",
};
</script>
<template>
    <main class="mt-5">
        <section v-if="author" class="container pr-3 pl-3">
            <div class="columns mt-3">
                <div class="column pb-0">                   
                    <AuthorHeader :author="author" />                                       
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <AuthorGallery :author="author" />                    
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <div class="box mt-5 mb-5">
                        <AuthorContact :author="author" />                    
                    </div> 
                </div>
            </div>
            <div class="columns">
                <div class="column">
                    <AuthorBio :author="author" />
                </div>
                <div class="column is-8">
                    <AuthorQuestionnaire :author="author" />
                </div>                
            </div>
            <div class="columns">
                <div class="column">                    
                    <hr/>
                    <AuthorLocation :author="author" />
                    <hr v-if="author.featuredVideoUrl"/>
                    <AuthorVideo :author="author" />
                    <hr/>
                    <AuthorInfo :author="author" />
                </div>
            </div>            
        </section>
        <section v-else  style="height:100vh;">
            <Loader />
        </section>
    </main>
</template>

<script>
import fetch from 'node-fetch';
import AuthorHeader from '@/components/Author/AuthorHeader.vue';
import AuthorContact from '@/components/Author/AuthorContact.vue';
import AuthorGallery from '@/components/Author/AuthorGallery.vue';
import AuthorQuestionnaire from '@/components/Author/AuthorQuestionnaire.vue';
import AuthorVideo from '@/components/Author/AuthorVideo.vue';
import AuthorInfo from '@/components/Author/AuthorInfo.vue';
import AuthorBio from '@/components/Author/AuthorBio.vue';
import AuthorLocation from '@/components/Author/AuthorLocation.vue';
import Loader from '@/components/Loader.vue';

export default {
    components: {
        AuthorHeader,
        AuthorContact,
        AuthorGallery,
        AuthorQuestionnaire,
        AuthorVideo,
        AuthorInfo,
        AuthorBio,
        AuthorLocation,
        Loader
    },
    data() {
        return {
            author: null
        };        
    },
    mounted() {
        var moniker = this.$route.params.moniker;
        fetch(`./mock-api/${moniker}.json`)
        .then(response => {          
            return response.json();
        })
        .then(jsondata => {
            this.author = jsondata;
        });
    }
}
</script>
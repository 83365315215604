<template>
  <section>
    <h1 class="title">{{ $t('legal.cookies') }}</h1>

    <h3 class="title">1. DEFINITION AND GENERIC FUNCTION OF COOKIES.</h3>
    <p class="has-text-justified">{{data.owner}}the owner of this website informs that cookies and/or similar technologies are used to store and retrieve information when you browse. In general, these technologies can be used for a variety of purposes, such as, for example, to recognize you as a user, obtain information about your browsing habits, or customize the way content is displayed. The specific uses we make of these technologies are described below.</p>
    
    <h3 class="title">2. INFORMATION ON THE TYPE OF COOKIES USED AND THEIR PURPOSE.</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Technical:</span> They are those necessary for navigation and the proper functioning of our website. They allow, for example, to control traffic and data communication, access restricted areas, carry out the purchase process of an order, use security elements, store content in order to broadcast videos or share content through social networks.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Customization:</span> They are those that allow the User to access the service with predefined characteristics according to a series of criteria, such as the language, the type of browser through which the service is accessed, the regional configuration from where the service is accessed, etc.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Analysis:</span> are those that, treated by us or by third parties, allow us to quantify the number of users and thus perform the measurement and statistical analysis of the use made by users of the service offered. To do this, your browsing on our website is analyzed in order to improve the supply of products or services that we offer.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Advertising:</span> They are those that allow the management, in the most efficient way possible, of the advertising spaces that could be included in our website.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Behavioral advertising:</span> are those that, processed by us or by third parties, allow us to analyze your browsing habits on the Internet so that we can show you advertising related to your browsing profile.
      </li>
    </ul>
    
    <h3 class="title">3. IDENTIFICATION OF WHO USES COOKIES:</h3>
    <p class="has-text-justified">The information obtained by cookies is processed only by our publisher.</p>

    <h3 class="title">4. CONSERVATION PERIOD</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Session cookies:</span> They are temporary cookies that remain in the cookie file of your browser until the user leaves the website, so that none is recorded on the hard drive of your computer. The information obtained through these cookies is used to analyze traffic patterns on the website. In the long run, this allows us to provide a better experience to improve the content and facilitate its use.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Persistent cookies:</span> are stored in the hard disk and our web reads them every time the User makes a new visit. A permanent cookie has a specific expiration date. The cookie will stop working after that date. We generally use these cookies to facilitate the purchase and registration services.
      </li>
    </ul>
    <p class="has-text-justified">The data obtained from both types of cookies will be retained as indicated in the privacy policy of our website: {{data.web}}</p>

    <p class="has-text-justified has-text-weight-bold">We use the following third party cookies:</p>

    <table class="table is-bordered">      
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Origin</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>cookieyes-consent</td>
          <td>Necessary</td>
          <td>CookieYes</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">CookieYes sets this cookie to remember users' consent preferences so that their preferences are respected on subsequent visits to this site. It does not collect or store any personal information about the site visitors.</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Analytics</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics sets this cookie to calculate visitor, session and campaign data and track site usage for the site's analytics report. The cookie stores information anonymously and assigns a randomly generated number to recognise unique visitors.</td>
        </tr>
        <tr>
          <td>_ga_*</td>
          <td>Analytics</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics sets this cookie to store and count page views.</td>
        </tr>
      </tbody>
    </table>

    <p class="has-text-centered">
      <a href="#" class="cky-banner-element">COOKIE SETTINGS PANEL</a>
    </p>

    <h3 class="title">5. ACTIVATION, RESTRICTION AND/OR DISABLING OF COOKIES</h3>
    <p class="has-text-justified">You can allow or block cookies, as well as delete your browsing data (including cookies) from the browser you use. Consult the options and instructions provided by your browser to do so. Please note that if you accept third-party cookies, you must delete them from your browser options.</p>

    <h3 class="title">6. DATA TRANSFERS TO THIRD COUNTRIES MADE BY THE PUBLISHER.</h3>
    <p class="has-text-justified">We do not transfer data to third countries.</p>

    <h3 class="title">7. PROFILING</h3>
    <p class="has-text-justified">We do not profile.</p>

    <h3 class="title">8. OTHER INFORMATION</h3>
    <p class="has-text-justified">In relation to the rest of the information required by article 13 RGPD you can see here <router-link to="/privacy">{{ $t('legal.privacy') }}</router-link>.</p>

  </section>
</template>

<script>
export default {
    name: 'CookiesPolicyEs',
    props: ['data']
}
</script>

<style>

</style>
<template>
    <section v-if="author && author.questions">
        <h5 class="title is-size-5">{{ $t('author.labels.questionnaire') }}</h5>
        <div class="content has-text-justified" v-for="question in author.questions" :key="question.title">
            <h6 class="title is-size-6 mt-5">{{ question.title }}</h6>
            <p class="is-italic">                
                {{ question.answer }}
            </p>            
        </div>
    </section>
</template>

<script>
export default {
    props: ['author'],
    name: 'AuthorQuestionnaire'
}
</script>
<template>
        <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery">
            <b-carousel-item v-for="(item, i) in imagesCount" :key="i">
                <a class="image main-image">
                    <img :src="getImgUrl(i)">
                </a>
            </b-carousel-item>
            <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-small"/>
            <template #indicators="props">
                <figure class="al image thumbnail" :draggable="false">
                    <img :draggable="false" :src="getImgUrl(props.i)" :title="props.i">
                </figure>
            </template>
        </b-carousel>
    
</template>

<script>
export default {
    props: ['imageLinks'],
    data() {
        return {
            gallery: false
        }
    },
    methods: {
        getImgUrl(value) {
            value += 1
            return this.imageLinks[value-1];            
        },
        switchGallery(value) {
            this.gallery = value
            if (value) {
                return document.documentElement.classList.add('is-clipped')
            } else {
                return document.documentElement.classList.remove('is-clipped')
            }
        }
    },
    computed: {
        imagesCount: function(){
            return this.imageLinks.length;
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
.main-image {
    margin:auto;
    width:95%;
    height:40vh;

    @include tablet {
        height:80vh;
    }
}

.main-image > img {
    height:100%;
    object-fit:contain;
}

.thumbnail {
    width:16vw;
    height:12vw;
    max-height:12vw;

    @include tablet {
        width:8vw;
        height:6vw;
        max-height:6vw;
    }
}

.thumbnail > img {
    height:100%;
    object-fit:contain;
}

.is-active .al img {
    border: 1px solid #fff;
    filter: grayscale(0%);
}
.al img {
    border: 1px solid transparent;
    filter: grayscale(100%);
}
</style>
<template>
  <main class="mt-5">
    <div class="container pr-3 pl-3">
      <div class="content">
        <PrivacyContentEs v-if="locale === 'es'" 
        :data="privacyDetails" />
        <PrivacyContentEn v-if="locale === 'en'"
        :data="privacyDetails" />
        <PrivacyContentFr v-if="locale === 'fr'"
        :data="privacyDetails" />
        
      </div>
    </div>
  </main>
</template>

<script>
  import PrivacyContentEs from '@/components/Privacy/PrivacyContentEs.vue';
  import PrivacyContentEn from '@/components/Privacy/PrivacyContentEn.vue';
  import PrivacyContentFr from '@/components/Privacy/PrivacyContentFr.vue';
  export default {
  components: {
    PrivacyContentEs,
    PrivacyContentEn,
    PrivacyContentFr
  },
  data() {
    return {
      privacyDetails:{
        web: "https://openmy.art",
        owner: "F. Martinez",
        cif:"Y5695733G",
        registeredOffice: "Calle Esteban de la Foz, Monte, 39012, Santander, España",        
        email: "info@openmy.art"
      }      
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  }  
};
</script>
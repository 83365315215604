<template>
    <section v-if="author && author.gallery">
        <div class="tile is-ancestor">
            <div class="tile is-parent">
                <div class="tile is-child is-relative">
                    <img
                        :src="author.gallery.largePreviewUrl"
                        :alt="$t('author.labels.large_preview')"
                        class="gallery-large"
                    />
                    <b-button
                        label="Ver galería"
                        class="gallery-button mobile-visible"
                        rounded
                        active
                        icon-left="camera"
                        icon-pack="fas"
                        @click="isComponentModalActive = true"
                    />
                </div>
            </div>
            <div class="tile is-3 is-vertical is-parent mobile-hidden">
                <div class="tile is-child">
                    <img
                        :src="author.gallery.smallPreviewUrls[0]"
                        alt="Placeholder image"
                        class="gallery-small"
                    />
                </div>
                <div class="tile is-child">
                    <img
                        :src="author.gallery.smallPreviewUrls[1]"
                        alt="Placeholder image"
                        class="gallery-small"
                    />
                </div>
            </div>
            <div class="tile is-3 is-vertical is-parent mobile-hidden">
                <div class="tile is-child">
                    <img
                        :src="author.gallery.smallPreviewUrls[2]"
                        alt="Placeholder image"
                        class="gallery-small"
                        style="border-top-right-radius:25px"
                    />
                </div>
                <div class="tile is-child is-relative">
                    <img
                        :src="author.gallery.smallPreviewUrls[3]"
                        alt="Placeholder image"
                        class="gallery-small"
                        style="border-bottom-right-radius:25px"
                    />
                    <b-button
                        :label="$t('author.labels.view_gallery')"
                        class="gallery-button mobile-hidden"
                        rounded
                        active
                        icon-left="camera"
                        icon-pack="fas"
                        @click="isComponentModalActive = true"
                    />
                </div>
            </div>
        </div>

        <b-modal
          v-model="isComponentModalActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          close-button-aria-label="Close"
          aria-modal
        >
          <template #default="props">
            <AuthorCarousel @close="props.close" :imageLinks="author.gallery.urls"></AuthorCarousel>
          </template>
        </b-modal>

    </section>
</template>

<script>
import AuthorCarousel from '@/components/Author/AuthorCarousel.vue';
export default {
    props: ['author'],
    name: 'AuthorGallery',
    components: {
      AuthorCarousel
    },
    data() {
      return {
        isComponentModalActive: false
      }
    }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/_all";
.gallery-large:hover,
.gallery-small:hover {
  opacity: 0.8;
  cursor: pointer;
}

.gallery-small {
  
  @include mobile {
    display: none;
  }
  @include tablet {
    display: block;    
    height: 200px;
  }
  @include desktop {
    width: 100%;
    object-fit: cover;
    height: 250px;
  }  
}

.mobile-visible {

  @include mobile {
    display: block;
  }

  @include tablet {
    display: none;
  }
}
.mobile-hidden {
  @include mobile {
    display: none;
  }
  @include tablet {
    display: block;
  }
}

.gallery-large {
  width: 100%;
  height: 530px;
  object-fit: cover;

  @include mobile {
    height: 250px;
    border-radius: 25px;
  }

  @include tablet {
    height: 430px;
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
  }
  
  @include desktop {
    height: 525px;
  }
}

.gallery-button {
  position: absolute;
  bottom: 16px;
  right: 16px;
}

</style>
<template>
  <section>
    <h1 class="title">{{ $t('legal.cookies') }}</h1>

    <h3 class="title">1. DEFINICIÓN Y FUNCIÓN GENÉRICA DE LAS COOKIES.</h3>
    <p class="has-text-justified">{{data.owner}}, el titular de este sitio web informa que se utilizan cookies y/o tecnologías similares que almacenan y recuperan información cuando navegas. En general, estas tecnologías pueden servir para finalidades muy diversas, como, por ejemplo, reconocerte como usuario, obtener información sobre tus hábitos de navegación, o personalizar la forma en que se muestra el contenido. Los usos concretos que hacemos de estas tecnologías se describen a continuación.</p>
    
    <h3 class="title">2. INFORMACIÓN SOBRE EL TIPO DE COOKIES QUE SE UTILIZAN Y SU FINALIDAD.</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Técnicas:</span> Son aquellas necesarias para la navegación y el buen funcionamiento de nuestro website. Permiten, por ejemplo, controlar el tráfico y la comunicación de datos, acceder a partes de acceso restringido, realizar el proceso de compra de un pedido, utilizar elementos de seguridad, almacenar contenidos para poder difundir vídeos o compartir contenidos a través de redes sociales.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">De personalización:</span> Son aquéllas que permiten al Usuario acceder al servicio con unas características predefinidas en función de una serie de criterios, como por ejemplo el idioma, el tipo de navegador a través del cual se accede al servicio, la configuración regional desde donde se accede al servicio, etc.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">De análisis:</span> son aquellas que, tratadas por nosotros o por terceros, nos permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Publicitarias:</span> Son aquéllas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que se pudieran incluir en nuestro website.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Publicitarias comportamentales:</span> son aquellas que, tratadas por nosotros o por terceros, nos permiten analizar sus hábitos de navegación en Internet para que podamos mostrarle publicidad relacionada con su perfil de navegación.
      </li>
    </ul>
    
    <h3 class="title">3. IDENTIFICACIÓN DE QUIÉN UTILIZA LAS COOKIES:</h3>
    <p class="has-text-justified">La información obtenida por las cookies puede ser tratada solo por el editor y/o también por terceros con los que nuestro editor haya contratado la prestación de un servicio para el cual se requiera el uso de cookies:</p>
    <ul>
      <li class="has-text-justified">        
        <span class="has-text-weight-bold">Cookies propias:</span> Son aquellas que se envían a su equipo desde nuestros propios equipos o dominios y desde el que prestamos el servicio que nos solicita.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Cookies de terceros:</span> Son aquellas que pertenecen y gestiona una tercera entidad colaboradora, tanto si son enviadas por nuestros propios equipos o por los de la propia entidad colaboradora. Como, por ejemplo, las usadas por redes sociales, o por contenido externo como Google Maps, las que permiten cuantificar el número de usuarios y así realizar la medición y análisis estadístico de la utilización que hacen los usuarios del servicio ofertado. Para ello se analiza su navegación en nuestra página web con el fin de mejorar la oferta de productos o servicios que le ofrecemos.
      </li>
    </ul>

    <h3 class="title">4. PERIODO DE CONSERVACIÓN</h3>
    <ul>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Cookies de sesión:</span> Son cookies temporales que permanecen en el archivo de cookies de su navegador hasta que el Usuario abandona la página web, por lo que ninguna queda registrada en el disco duro de su ordenador. La información obtenida por medio de estas cookies, sirven para analizar pautas de tráfico en el website. A la larga, esto nos permite proporcionar una mejor experiencia para mejorar el contenido y facilitar su uso.
      </li>
      <li class="has-text-justified">
        <span class="has-text-weight-bold">Cookies persistentes:</span> son almacenadas en el disco duro y nuestra web las lee cada vez que el Usuario realiza una nueva visita. Una cookie permanente posee una fecha de expiración determinada. La cookie dejará de funcionar después de esa fecha. Estas cookies las utilizamos, generalmente, para facilitar los servicios de compra y registro.
      </li>
    </ul>
    <p class="has-text-justified">Los datos obtenidos a partir de ambos tipos de cookies serán conservados conforme se indica en la política de privacidad de nuestra página web: {{data.web}}</p>

    <p class="has-text-justified has-text-weight-bold">Utilisamos los siguientes cookies de terceros:</p>

    <table class="table is-bordered">      
      <thead>
        <tr>
          <th>Nombre</th>
          <th>Tipo</th>
          <th>Origen</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>cookieyes-consent</td>
          <td>Necesaria</td>
          <td>CookieYes</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">CookieYes establece esta cookie para recordar las preferencias de consentimiento de los usuarios, de modo que se respeten sus preferencias en las siguientes visitas a este sitio. No recoge ni almacena ninguna información personal sobre los visitantes del sitio.</td>
        </tr>
        <tr>
          <td>_ga</td>
          <td>Analítica</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics establece esta cookie para calcular los datos de los visitantes, de las sesiones y de las campañas y para hacer un seguimiento del uso del sitio para el informe de análisis del sitio. La cookie almacena la información de forma anónima y asigna un número generado aleatoriamente para reconocer a los visitantes únicos.</td>
        </tr>
        <tr>
          <td>_ga_*</td>
          <td>Analítica</td>
          <td>Google</td>
        </tr>
        <tr>
          <td colspan="3" class="is-italic">Google Analytics sets this cookie to store and count page views.</td>
        </tr>
      </tbody>
    </table>

    <p class="has-text-centered">
      <a href="#" class="cky-banner-element">PANEL DE CONFIGURACION DE LAS COOKIES</a>
    </p>

    <h3 class="title">5. ACTIVACIÓN, RESTRICCIÓN Y/O INHABILITACIÓN DE COOKIES</h3>
    <p class="has-text-justified">Puede usted permitir o bloquear las cookies, así como borrar sus datos de navegación (incluidas las cookies) desde el navegador que usted utiliza. Consulte las opciones e instrucciones que ofrece su navegador para ello. Tenga en cuenta que, si acepta las cookies de terceros, deberá eliminarlas desde las opciones del navegador.</p>

    <h3 class="title">6. TRANSFERENCIAS DE DATOS A TERCEROS PAÍSES REALIZADAS POR EL EDITOR.</h3>
    <p class="has-text-justified">No realizamos transferencias de datos a terceros países.</p>

    <h3 class="title">7. ELABORACIÓN DE PERFILES</h3>
    <p class="has-text-justified">No elaboramos perfiles.</p>

    <h3 class="title">8. RESTO DE INFORMACIÓN</h3>
    <p class="has-text-justified">En relación con el resto de información exigida por el artículo 13 RGPD puedes verla aquí <router-link to="/privacy">{{ $t('legal.privacy') }}</router-link>.</p>

  </section>
</template>

<script>
export default {
    name: 'CookiesPolicyEs',
    props: ['data']
}
</script>

<style>

</style>
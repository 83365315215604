<template>     
        <div class="card has-background-primary" style="height:100%;cursor:pointer;" @click="goToAuthor(author.moniker)">
            <div class="card-image">
                <figure class="image is-4by3">
                <img :src="author.thumbnailUrl"
                     :alt="author.name">
                </figure>
            </div>
            <div class="card-content has-background-primary">
                <div class="media">
                <div class="media-content" style="overflow-y:hidden">
                    <p class="title is-5 has-text-primary-light">
                    {{ author.name }}
                    </p>
                    <p class="subtitle is-6 has-text-primary-light">
                    {{ author.area }}
                    </p>
                </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'FeaturedAuthorCard',
    props:['author'],
    methods: {
        goToAuthor(moniker) {
            this.$router.push(`/author/${moniker}`);
        }
    }
}
</script>
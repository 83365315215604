<template>
  <section>
    <p>
      ¿Te imaginas poder compartir una conversación con los maestros y maestras
      de la historia del arte? Ahora solo puedes ver sus obras en los museos e
      imaginarte cómo podrían haber sido, porque ya dejaron sus cuerpos.
    </p>
    <p>
      Open My Art te permitirá elegir artistas vivos, muy especiales,
      singulares, únicos, a los que podrás pedirles una cita para visitar su
      espacio creativo, su corazón. Una oportunidad única de sentir la pasión,
      las motivaciones, los sentimientos, las emociones, los procesos, los
      materiales, el gran universo creativo interno de la mano de los autores
      que serán los maestros y maestras en los grandes museos del futuro, cuando
      hayan muerto.
    </p>
  </section>
</template>

<script>
export default {
  name: "FaqWhatContentEs",
};
</script>
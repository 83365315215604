<template>
  <section>
    <h1 class="title">{{ $t('legal.privacy') }}</h1>
        <h3 class="title">1. Who is the data controller?</h3>
        <p>{{data.owner}}</p>
        <p>{{data.cif}}</p>
        <p>{{data.registeredOffice}}</p>
        <p>{{data.email}}</p>

        <h3 class="title">2. What kind of data do we have about you and how did we obtain it?</h3>
        <ol type="I">
          <li>Identification details</li>
          <li>Postal or e-mail addresses</li>
          <li>Commercial information</li>
          <li>Images</li>
        </ol>
        <p class="has-text-justified">We have obtained all of the above-mentioned data either directly from you by submitting a commercial offer, contractual proposal, etc. or from your company by providing us with the identification data and other information necessary to carry out the purpose of the contractual relationship between the parties. It shall be your or your company's obligation to provide us with the updated data in the event of any changes.</p>
        
        <h3 class="title">3. For what purpose do we process your data?</h3>
        <p class="has-text-justified">We process the data provided by the persons concerned in order to manage various activities arising from specific procedures carried out in the field of sales, after-sales service, supplier management, quality of services, etc.. In this way, we will use your data to carry out one of the following actions:</p>
        <ol type="I">
          <li class="has-text-justified">Provision of the requested services or delivery of the purchased product.</li>
          <li class="has-text-justified">Sending the information requested through the contact form on our website or any other means of contact with our company,</li>
          <li class="has-text-justified">To provide both potential customers and our customers with offers of products and services of interest to them,</li>
          <li class="has-text-justified">To carry out the administrative, fiscal and accounting management of our clients and/or suppliers,</li>
          <li class="has-text-justified">Conduct satisfaction surveys, market research, etc. in order to provide you with the most suitable offers and optimized quality of service, etc.</li>
          <li class="has-text-justified">Employee labor management.</li>
        </ol>
        <p class="has-text-justified">To carry out any of these purposes, electronic means such as email, WhatsApp, etc. may be used.</p>

        <h3 class="title">4. How long will we keep your data?</h3>
        <p class="has-text-justified">Personal data relating to individuals linked to potential customers, customers and suppliers that we collect through the various contact forms and / or collection of information (including data collected through cookies, if applicable) will be retained until their deletion is requested by the person concerned. The data provided by our customers and suppliers will be kept as long as the business relationship between the parties is maintained, respecting in any case the minimum legal periods of conservation according to the subject matter.</p>
        <p class="has-text-justified">Employee data will be kept for as long as the employment relationship is maintained and in any case, as prescribed by labor legislation to that effect.</p>
        <p class="has-text-justified">In the case of images obtained by the video surveillance system, the period of conservation of the same will be 1 month, in accordance with the provisions of Article 22.3 of the LOPDGDD, unless they should be retained for delivery to the competent authorities.</p>
        <p class="has-text-justified">In any event, we will keep your personal data for the period of time that is reasonably necessary taking into account our needs to respond to issues that arise or resolve problems, make improvements, activate new services and comply with requirements under applicable law. This means that we may retain your personal data for a reasonable period of time even after you have stopped using our products or you have stopped using this website. After this period, your personal data will be deleted from all our systems.</p>

        <h3 class="title">5. What is the basis of legitimacy for the processing of your data?</h3>
        <p class="has-text-justified">According to the type of data processing, the basis of legitimacy is as follows:</p>
        
        <h5 class="subtitle">Accounting</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> invoicing management with customers and/or suppliers. 
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Maintenance, development and control of the contractual relationship between the parties.
          </div>
        </div>

        <h5 class="subtitle">Tax management</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> application of withholdings, allowances, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Maintenance, development and control of the contractual relationship between the parties; Compliance with legal obligations.
          </div>
        </div>

        <h5 class="subtitle">Administration</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> logistics management, warehouse, customer deliveries, goods receiving, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Maintenance, development and control of the contractual relationship between the parties.
          </div>
        </div>

        <h5 class="subtitle">Marketing</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> Commercial actions about our products or services directed to our customers or those persons who have requested information about our products and services in the past, including conducting customer satisfaction surveys.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Free and unequivocal consent of the person concerned (potential customers), we note that the withdrawal of this consent in no case may condition the execution of the contract between the parties; legitimate interest of the company on the promotion and marketing of products or services similar to those obtained or requested by the persons concerned in the past.
          </div>
        </div>

        <h5 class="subtitle">If you are an employee, for Labor Management</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> administrative, accounting and tax management of employees.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Execution of a labor contract. Legal compliance.
          </div>
        </div>

        <h5 class="subtitle">Management of working hours</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> The start and end time of the workday, as well as overtime.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Execution of a labor contract. Legal compliance.
          </div>
        </div>

        <h5 class="subtitle">Images</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Treatment:</span> image/voice publication on company website, company books, RRSS in order to make the staff known.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Basis of legitimacy:</span> Express consent of the interested party.
          </div>
        </div>

        <p class="has-text-justified">In the event that you do not provide your personal data, we will not be able to execute your contract, fulfill your legal obligations or those of the public authorities.</p>

        <h3 class="title">6. To whom will your data be communicated?</h3>
        <p class="has-text-justified">We will not pass on your personal data to any third party company that intends to use it for its direct marketing activities, unless you have expressly authorized us to do so.</p>
        <p class="has-text-justified">We inform you that we may provide your personal data to government agencies and competent authorities in those cases where we are legally required to do so or in cases where, acting in good faith, we believe that such action is reasonably necessary to comply with legal process; to respond to any legal claim or demand; or to protect the rights of the company or its customers and the general public.</p>
        <p class="has-text-justified">We inform you that your data will not be transferred or communicated to third parties, the company is solely responsible for their treatment and custody.</p>
        <p class="has-text-justified">We will provide your personal data to third parties (e.g. Internet service providers that help us administer our website or carry out contracted services, IT support and maintenance companies, logistics companies, tax and accounting consultancies, etc.). In any case, these third parties must maintain, at all times, the same levels of security as we do in relation to your personal data and, where necessary, will be bound by legal commitments to keep your personal data private and secure, and also to only use the information following specific instructions from the company.</p>

        <h3 class="title">7. Data transfers to third countries?</h3>
        <p class="has-text-justified">No transfer of data to third countries is foreseen.</p>

        <h3 class="title">8. What are your rights as a data subject?</h3>
        <p class="has-text-justified">Any person has the right to obtain confirmation as to whether or not we are processing personal data concerning him/her.</p>
        <p class="has-text-justified">In particular, the persons concerned may request the right of access to their personal data, as well as to receive them in a common format and machine-readable if the processing is carried out by electronic means (right of portability).</p>
        <p class="has-text-justified">Likewise, interested parties may request the right to rectify inaccurate data or, where appropriate, request its deletion when, among other reasons, the data is no longer necessary for the purposes for which it was collected.</p>
        <p class="has-text-justified">In addition, in certain circumstances, data subjects may request the limitation of the processing of their data, or in certain circumstances and for reasons related to their particular situation, data subjects may exercise their right to object to the processing of their data. We will stop processing the data, except for compelling legitimate reasons, or the exercise or defense of possible claims or in those exceptions established in the applicable regulations.</p>
        <p class="has-text-justified">We also inform you that you have the right to withdraw your consents given at any time, without affecting the lawfulness of the processing based on the consent prior to its withdrawal.</p>
        <p class="has-text-justified">Likewise, the User is informed that at any time he/she may exercise the aforementioned rights by writing to us using the contact information listed in point 1, attaching a copy of his/her ID card.</p>
        <p class="has-text-justified">You also have the right to file a complaint with the Spanish Data Protection Agency, especially when you have not obtained satisfaction in the exercise of your rights.</p>
        <p class="has-text-centered">
          <a href="http://www.agpd.es/portalwebAGPD/index-ides-idphp.php" target="_blank">Spanish Data Protection Agency</a></p>
        <p class="has-text-justified">On the other hand, in accordance with the provisions of Law 34/2002, of July 11, 2002, of Information Society Services and Electronic Commerce, we undertake not to send commercial information if you have let us know.</p>

  </section>
</template>

<script>
export default {
    name: 'PrivacyContentEs',
    props: ['data']
}
</script>

<style>

</style>
<template>
  <b-collapse
    class="card"
    animation="slide"
    :open="isOpen"
    @open="isOpen = !isOpen"
  >
    <template #trigger="props">
      <div class="card-header" role="button" :aria-expanded="props.open">
        <p class="card-header-title">
          {{ $t("faq.question_how") }}
        </p>
        <a class="card-header-icon">
          <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'">
          </b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content">
        <FaqHowContentEs v-if="locale === 'es'" />
        <FaqHowContentFr v-if="locale === 'fr'" />
        <FaqHowContentEn v-if="locale === 'en'" />
      </div>
    </div>
  </b-collapse>
</template>

<script>
import FaqHowContentEs from "@/components/Home/Faq/FaqHowContentEs.vue";
import FaqHowContentFr from "@/components/Home/Faq/FaqHowContentFr.vue";
import FaqHowContentEn from "@/components/Home/Faq/FaqHowContentEn.vue";
export default {
  name: "FaqHow",
  components: {
    FaqHowContentEs,
    FaqHowContentFr,
    FaqHowContentEn,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
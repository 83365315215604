<template>
    <div class="is-16by9">
        <GmapMap 
            :center="coordinates" 
            :zoom='9' 
            :options="options"
            style='width:100%;min-height:480px;'>
            <GmapMarker :position="coordinates" @click="center=coordinates" />
        </GmapMap>
    </div>
</template>

<script>
export default {
    name: 'AuthorGoogleMap',
    props: ['coordinates'],
    data() {
        return {
            options: {
                streetViewControl: false,
                mapTypeControl: false
            }
        };
    }
}
</script>

<style>

</style>
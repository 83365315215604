<template>
  <section>
    <h1 class="title">{{ $t('legal.privacy') }}</h1>
        <h3 class="title">1. qui est le responsable du traitement des données ?</h3>
        <p>{{data.owner}}</p>
        <p>{{data.cif}}</p>
        <p>{{data.registeredOffice}}</p>
        <p>{{data.email}}</p>

        <h3 class="title">2. quel type de données possédons-nous à votre sujet et comment les avons-nous obtenues ?</h3>
        <ol type="I">
          <li>Données d'identification</li>
          <li>Adresses postales ou électroniques</li>
          <li>Informations commerciales</li>
          <li>Images</li>
        </ol>
        <p class="has-text-justified">Nous avons obtenu toutes les données susmentionnées soit directement de vous en soumettant une offre commerciale, une proposition contractuelle, etc., soit de votre entreprise en nous fournissant les données d'identification et autres informations nécessaires à la réalisation de l'objet de la relation contractuelle entre les parties. Il est de votre obligation ou de celle de votre entreprise de nous fournir les données actualisées en cas de modification.</p>
        
        <h3 class="title">3. dans quel but traitons-nous vos données ?</h3>
        <p class="has-text-justified">Nous traitons les données qui nous sont fournies par les personnes concernées afin de gérer diverses activités découlant de procédures spécifiques menées dans les domaines de la vente, du service après-vente, de la gestion des fournisseurs, de la qualité des services, etc. Ainsi, nous utiliserons vos données pour réaliser l'une des actions suivantes :</p>
        <ol type="I">
          <li class="has-text-justified">Fourniture des services demandés ou livraison du produit acheté.</li>
          <li class="has-text-justified">Envoi des informations demandées via le formulaire de contact de notre site web ou tout autre moyen de contact avec notre société,</li>
          <li class="has-text-justified">Pour fournir aux clients potentiels et à nos clients des offres de produits et de services qui les intéressent,</li>
          <li class="has-text-justified">Assurer la gestion administrative, fiscale et comptable de nos clients et/ou fournisseurs,</li>
          <li class="has-text-justified">Réaliser des enquêtes de satisfaction, des études de marché, etc. afin de pouvoir vous proposer les offres les plus adaptées et une qualité de service optimisée, etc.</li>
          <li class="has-text-justified">Gestion du travail des employés.</li>
        </ol>
        <p class="has-text-justified">Des moyens électroniques tels que le courrier électronique, WhatsApp, etc. peuvent être utilisés pour atteindre l'un de ces objectifs.</p>

        <h3 class="title">4. combien de temps conserverons-nous vos données ?</h3>
        <p class="has-text-justified">Les données personnelles relatives aux personnes liées aux clients potentiels, aux clients et aux fournisseurs que nous collectons par le biais des différents formulaires de contact et/ou de collecte d'informations (y compris les données collectées par le biais de cookies, le cas échéant) seront conservées jusqu'à ce que leur suppression soit demandée par la partie intéressée. Les données fournies par nos clients et fournisseurs seront conservées tant que la relation d'affaires entre les parties est maintenue, en respectant dans tous les cas les durées minimales légales de conservation en fonction du sujet traité.</p>
        <p class="has-text-justified">Les données des employés sont conservées aussi longtemps que la relation de travail est maintenue et, en tout état de cause, conformément aux dispositions de la législation du travail pertinente.</p>
        <p class="has-text-justified">Dans le cas des images obtenues par le système de vidéosurveillance, le délai de conservation de celles-ci est de 1 mois, conformément aux dispositions de l'article 22.3 de la LOPDGDD, sauf si elles doivent être conservées pour être remises aux autorités compétentes.</p>
        <p class="has-text-justified">En tout état de cause, nous conserverons vos données personnelles aussi longtemps que cela sera raisonnablement nécessaire compte tenu de nos besoins pour répondre aux questions qui se posent ou résoudre les problèmes, apporter des améliorations, activer de nouveaux services et nous conformer aux exigences de la loi applicable. Cela signifie que nous pouvons conserver vos données personnelles pendant une période raisonnable, même après que vous avez cessé d'utiliser nos produits ou que vous avez cessé d'utiliser ce site web. Après cette période, vos données personnelles seront supprimées de tous nos systèmes.</p>

        <h3 class="title">5. quelle est la base légitime du traitement de vos données ?</h3>
        <p class="has-text-justified">Selon le type de traitement des données, la base de légitimité est la suivante :</p>
        
        <h5 class="subtitle">Gestion comptable</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> la gestion de la facturation avec les clients et/ou les fournisseurs. 
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Maintien, développement et contrôle de la relation contractuelle entre les parties.
          </div>
        </div>

        <h5 class="subtitle">Gestion fiscale</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> application des déductions, remises, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Maintien, développement et contrôle de la relation contractuelle entre les parties ; Respect des obligations légales.
          </div>
        </div>

        <h5 class="subtitle">Gestion administrative</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> gestion de la logistique, entrepôt, livraisons aux clients, réception des marchandises, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Maintien, développement et contrôle de la relation contractuelle entre les parties.
          </div>
        </div>

        <h5 class="subtitle">Marketing</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> Commercialiser nos produits ou services à nos clients ou à ceux qui nous ont demandé des informations sur nos produits et services dans le passé, y compris en menant des enquêtes de satisfaction auprès des clients.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Consentement libre et non équivoque de l'intéressé (clients potentiels), nous vous informons que le retrait de ce consentement ne peut en aucun cas conditionner l'exécution du contrat entre les parties ; intérêt légitime de l'entreprise dans la promotion et la commercialisation de produits ou services similaires à ceux obtenus ou demandés par les intéressés dans le passé.
          </div>
        </div>

        <h5 class="subtitle">Si vous êtes un employé, pour la gestion du travail</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> la gestion administrative, comptable et fiscale des salariés.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Exécution d'un contrat de travail. Conformité légale.
          </div>
        </div>

        <h5 class="subtitle">Gestion des enregistrements du temps de travail</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> l'heure de début et de fin de la journée de travail, ainsi que les heures supplémentaires.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Exécution d'un contrat de travail. Conformité légale.
          </div>
        </div>

        <h5 class="subtitle">Images</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Traitement :</span> publication de l'image/voix sur le site web de l'entreprise, les livres d'entreprise, le RRSS afin de sensibiliser le personnel.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base légitime :</span> Consentement exprès de la personne concernée.
          </div>
        </div>

        <p class="has-text-justified">Dans le cas où vous ne fournissez pas vos données personnelles, nous ne serons pas en mesure d'exécuter votre contrat, de remplir vos obligations légales ou celles des autorités publiques.</p>

        <h3 class="title">6. à quels destinataires vos données seront-elles divulguées ?</h3>
        <p class="has-text-justified">Nous ne transmettrons pas vos données personnelles à des tiers qui ont l'intention de les utiliser à des fins de marketing direct, sauf si vous nous avez expressément autorisés à le faire.</p>
        <p class="has-text-justified">Veuillez noter que nous pouvons divulguer vos données personnelles à des agences gouvernementales et à des autorités compétentes lorsque nous sommes légalement tenus de le faire ou lorsque, agissant de bonne foi, nous pensons qu'une telle action est raisonnablement nécessaire pour se conformer à une procédure légale ; pour répondre à toute réclamation ou demande légale ; ou pour protéger les droits de la société ou de ses clients et du grand public.</p>
        <p class="has-text-justified">Nous vous informons que vos données ne seront pas transférées ou communiquées à des tiers, l'entreprise est seule responsable de leur traitement et de leur garde.</p>
        <p class="has-text-justified">Nous fournirons vos données personnelles à des tiers (par exemple, des fournisseurs de services Internet qui nous aident à administrer notre site web ou à exécuter des services contractuels, des sociétés d'assistance et de maintenance informatique, des sociétés de logistique, des cabinets de conseil fiscal et comptable, etc.) En tout état de cause, ces tiers doivent à tout moment maintenir les mêmes niveaux de sécurité que nous en ce qui concerne vos données à caractère personnel et, le cas échéant, sont tenus par des obligations légales de préserver la confidentialité et la sécurité de vos données à caractère personnel, ainsi que d'utiliser les informations uniquement conformément aux instructions spécifiques de l'entreprise.</p>

        <h3 class="title">7. les transferts de données vers des pays tiers ?</h3>
        <p class="has-text-justified">Il n'est pas prévu de transférer des données vers des pays tiers.</p>

        <h3 class="title">8. quels sont vos droits en tant que personne concernée ?</h3>
        <p class="has-text-justified">Toute personne a le droit d'obtenir la confirmation que nous traitons ou non des données personnelles la concernant.</p>
        <p class="has-text-justified">En particulier, les personnes concernées peuvent demander le droit d'accéder à leurs données personnelles, ainsi que de les recevoir dans un format commun, lisible par machine, si le traitement est effectué par voie électronique (droit de portabilité).</p>
        <p class="has-text-justified">De même, les intéressés peuvent demander le droit de rectifier des données inexactes ou, le cas échéant, de demander leur suppression lorsque, entre autres raisons, les données ne sont plus nécessaires aux fins pour lesquelles elles ont été collectées.</p>
        <p class="has-text-justified">En outre, dans certaines circonstances, les personnes concernées peuvent demander la limitation du traitement de leurs données, ou dans certaines circonstances et pour des raisons liées à leur situation particulière, les personnes concernées peuvent exercer leur droit d'opposition au traitement de leurs données. Nous cesserons de traiter les données, sauf pour des raisons légitimes impérieuses, ou pour l'exercice ou la défense d'éventuelles réclamations ou dans les exceptions établies dans la réglementation applicable.</p>
        <p class="has-text-justified">Nous vous informons également que vous avez le droit de retirer vos consentements donnés à tout moment, sans que cela n'affecte la légalité du traitement fondé sur le consentement avant son retrait.</p>
        <p class="has-text-justified">L'Utilisateur est également informé qu'il peut à tout moment exercer les droits susmentionnés en nous écrivant aux coordonnées indiquées au point 1, en joignant une copie de sa carte d'identité.</p>
        <p class="has-text-justified">Vous avez également le droit d'introduire une plainte auprès de l'Agence espagnole de protection des données, notamment lorsque vous n'avez pas obtenu satisfaction dans l'exercice de vos droits.</p>
        <p class="has-text-centered">
          <a href="http://www.agpd.es/portalwebAGPD/index-ides-idphp.php" target="_blank">Agence espagnole de protection des données</a></p>
        <p class="has-text-justified">D'autre part, conformément aux dispositions de la loi 34/2002, du 11 juillet, sur les services de la société de l'information et le commerce électronique, nous nous engageons à ne pas envoyer d'informations commerciales si vous nous l'avez fait savoir.</p>

  </section>
</template>

<script>
export default {
    name: 'PrivacyContentFr',
    props: ['data']
}
</script>

<style>

</style>
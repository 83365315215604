<template>
  <p>
    Recherchez la province, choisissez l'auteur que vous souhaitez rencontrer
    pour vous plonger dans son univers créatif. Demandez un rendez-vous. Vous
    recevrez un courriel pour confirmer votre réservation. Facile ?
  </p>
</template>

<script>
export default {
  name: "FaqHowContentFr",
};
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AuthorView from '../views/AuthorView.vue'
import ContactUsView from '../views/ContactUsView.vue'
import PrivacyView from '../views/PrivacyView.vue'
import CookiesView from '../views/CookiesView.vue'
import LegalView from '../views/LegalView.vue'
import AboutView from '../views/AboutView.vue'
import AreaView from '../views/AreaView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  },
  {
    path: '/legal',
    name: 'legal',
    component: LegalView
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: PrivacyView
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: CookiesView
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUsView
  },
  {
    path: '/author/:moniker',
    name: 'author',
    component: AuthorView,
    props: true
  },
  {
    path: '/area/:moniker',
    name: 'area',
    component: AreaView,
    props: true
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
})

export default router

<template>
  <b-collapse
    class="card"
    animation="slide"
    :open="isOpen"
    @open="isOpen = !isOpen"
  >
    <template #trigger="props">
      <div class="card-header" role="button" :aria-expanded="props.open">
        <p class="card-header-title">
          {{ $t("faq.question_join") }}
        </p>
        <a class="card-header-icon">
          <b-icon pack="fas" :icon="props.open ? 'caret-up' : 'caret-down'">
          </b-icon>
        </a>
      </div>
    </template>
    <div class="card-content">
      <div class="content">
        <FaqJoinContentEs v-if="locale === 'es'" />
        <FaqJoinContentFr v-if="locale === 'fr'" />
        <FaqJoinContentEn v-if="locale === 'en'" />
      </div>
    </div>
  </b-collapse>
</template>

<script>
import FaqJoinContentEs from "@/components/Home/Faq/FaqJoinContentEs.vue";
import FaqJoinContentEn from "@/components/Home/Faq/FaqJoinContentEn.vue";
import FaqJoinContentFr from "@/components/Home/Faq/FaqJoinContentFr.vue";
export default {
  name: "FaqJoin",
  components: {
    FaqJoinContentEs,
    FaqJoinContentEn,
    FaqJoinContentFr,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale;
    },
  },
};
</script>
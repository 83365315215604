<template>
    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
</template>

<script>
export default {
    data(){
        return {
            isFullPage: false,
            isLoading: true
        }
    }
}
</script>
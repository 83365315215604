<template>
  <section>
    <h1 class="title">{{ $t('legal.notice') }}</h1>
        <h3 class="title">1. PURPOSE AND ACCEPTANCE</h3>
        <p class="has-text-justified">This legal notice regulates the use of the website {{data.web}} (hereinafter, THE WEBSITE), owned by {{data.owner}} (hereinafter, THE OWNER OF THE WEBSITE).</p>
        <p class="has-text-justified">Browsing the website of THE OWNER OF THE WEBSITE attributes the condition of user of the same and implies full and unreserved acceptance of each and every one of the provisions included in this Legal Notice, which may undergo modifications.</p>
        <p class="has-text-justified">The user agrees to make proper use of the website in accordance with the laws, good faith, public order, traffic uses and this Legal Notice. The user will be liable to THE OWNER OF THE WEBSITE or third parties for any damages that may be caused as a result of a breach of this obligation.</p>
        
        <h3 class="title">2. IDENTIFICATION</h3>
        <p class="has-text-justified">THE OWNER OF THE WEBSITE, in compliance with Law 34/2002, of July 11, 2002, of services of the information society and electronic commerce, informs you that:</p>        
        <ul>          
          <li class="has-text-justified">Its CIF/NIF/NIE is: {{data.cif}}</li>
          <li class="has-text-justified">Its registered office is at:  {{data.registeredOffice}}</li>
        </ul>       

        <h3 class="title">3. COMMUNICATIONS</h3>
        <p class="has-text-justified">All notifications and communications between users and THE OWNER OF THE WEBSITE will be considered effective, for all purposes, when made via postal mail or any other means of those detailed below:</p>
        <ul>          
          <li class="has-text-justified">Email: {{data.email}}</li>
        </ul>

        <h3 class="title">4. CONDITIONS OF ACCESS AND USE</h3>
        <p class="has-text-justified">The website and its services are free and open access, however, THE OWNER OF THE WEBSITE conditions the use of some of the services offered on its website to the prior completion of the corresponding form.</p>
        <p class="has-text-justified">The user guarantees the authenticity and timeliness of all data communicated to THE OWNER OF THE WEBSITE and shall be solely responsible for any false or inaccurate statements made.</p>
        <p class="has-text-justified">The user expressly agrees to make appropriate use of the contents and services of THE OWNER OF THE WEBSITE and not to use them for, among others:</p>
        <ol type="a">
          <li class="has-text-justified">Disseminate criminal, violent, pornographic, racist, xenophobic, offensive, terrorist apology or, in general, contrary to law or public order.</li>
          <li class="has-text-justified">Introduce into the network computer viruses or perform actions likely to alter, damage, interrupt or generate errors or damage to electronic documents, data or physical and logical systems of THE OWNER OF THE WEBSITE or third parties; as well as hinder the access of other users to the website and its services through the mass consumption of computing resources through which THE OWNER OF THE WEBSITE provides its services.</li>
          <li class="has-text-justified">Attempt to access the email accounts of other users or restricted areas of the computer systems of THE OWNER OF THE WEBSITE or third parties and, where appropriate, extract information.</li>
          <li class="has-text-justified">Violate the rights of intellectual or industrial property, as well as violate the confidentiality of the information of THE OWNER OF THE WEBSITE or third parties.</li>
          <li class="has-text-justified">Impersonate the identity of another user, public administrations or a third party.</li>
          <li class="has-text-justified">Reproduce, copy, distribute, make available to, or any other form of public communication, transform or modify the contents, unless authorized by the owner of the corresponding rights or it is legally permitted.</li>
          <li class="has-text-justified">Collect data for advertising purposes and send advertising of any kind and communications for sales or other commercial purposes without prior request or consent.</li>
        </ol>

        <h3 class="title">5. INTELLECTUAL AND INDUSTRIAL PROPERTY</h3>
        <p class="has-text-justified">All the contents of the website, such as texts, photographs, graphics, images, icons, technology, software, as well as its graphic design and source codes, constitute a work whose property belongs to THE OWNER OF THE WEBSITE, and none of the exploitation rights over them beyond what is strictly necessary for the correct use of the website may be understood to be transferred to the user.</p>
        <p class="has-text-justified">In short, users accessing this website may view the contents and make, where appropriate, authorized private copies provided that the elements reproduced are not subsequently transferred to third parties, nor are they installed on servers connected to networks, nor are they subject to any type of exploitation.</p>
        <p class="has-text-justified">Also, all trademarks, trade names or logos of any kind that appear on the website are owned by THE OWNER OF THE WEBSITE, without being understood that the use or access to it gives the user any right over them.</p>
        <p class="has-text-justified">The distribution, modification, transfer or public communication of the contents and any other act that has not been expressly authorized by the holder of the exploitation rights are prohibited.</p>

        <h3 class="title">6. HYPER LINKS</h3>
        <p class="has-text-justified">The establishment of a hyperlink does not imply in any case the existence of relations between THE WEBSITE OWNER and the owner of the website in which it is established, nor the acceptance and approval by THE WEBSITE OWNER of its contents or services. Those who intend to establish a hyperlink must first request written authorization from THE WEBSITE OWNER. In any case, the hyperlink will only allow access to the home-page or home page of our website, also must refrain from making false, inaccurate or incorrect statements or indications about THE OWNER OF THE WEBSITE, or include illegal content, contrary to morality and public order.</p>
        <p class="has-text-justified">THE OWNER OF THE WEBSITE is not responsible for the use that each user makes of the materials made available on this website nor for the actions carried out on the basis of the same.</p>
        <p class="has-text-justified">In hyperlinks to other sites, as is also the case of social networks, THE OWNER OF THE WEBSITE will not exercise any control over these sites and content, will not assume any responsibility for the contents of any link belonging to other websites or guarantee the technical availability, quality, reliability, accuracy, comprehensiveness, accuracy and validity of any material or information contained in any such hyperlinks or other Internet sites.</p>

        <h3 class="title">7. EXCLUSION OF WARRANTIES AND LIABILITY</h3>
        <p class="has-text-justified">The content of this web site is of a general nature and is for information purposes only, without fully guaranteeing access to all content, nor its completeness, correctness, validity or timeliness, nor its suitability or usefulness for a specific purpose.</p>
        <p class="has-text-justified">THE OWNER OF THE WEBSITE excludes, to the extent permitted by law, any liability for damages of any kind arising from:</p>
        <ol type="a">
          <li class="has-text-justified">The impossibility of accessing the website or the lack of truthfulness, accuracy, completeness and/or timeliness of the contents, as well as the existence of vices and defects of any kind of content transmitted, disseminated, stored, made available, accessed through the website or the services offered.</li>
          <li class="has-text-justified">The presence of viruses or other elements in the contents that may cause alterations in computer systems, electronic documents or user data.</li>
          <li class="has-text-justified">Failure to comply with the laws, good faith, public order, traffic uses and this legal notice as a result of improper use of the website. In particular, and by way of example, THE OWNER OF THE WEBSITE is not responsible for the actions of third parties that violate intellectual and industrial property rights, business secrets, rights to honor, personal and family privacy and self-image, as well as the rules on unfair competition and illegal advertising.</li>
        </ol>
        <p class="has-text-justified">Also, THE OWNER OF THE WEBSITE disclaims any responsibility for information that is outside this website and is not managed directly by our webmaster. The function of the links that appear on this website is exclusively to inform the user about the existence of other sources likely to expand the content offered by this website. THE OWNER OF THE WEBSITE does not guarantee nor is responsible for the operation or accessibility of the linked sites; nor suggests, invites or recommends the visit to them, so it will not be responsible for the result obtained. THE OWNER OF THE WEBSITE is not responsible for the establishment of hyperlinks by third parties.</p>

        <h3 class="title">8. PROCEDURE IN THE EVENT OF UNLAWFUL ACTIVITIES</h3>
        <p class="has-text-justified">If THE OWNER OF THE WEBSITE becomes aware of the use of the services of the website for activities harmful to rights or that constitute unlawful acts, it will take the necessary measures for the repression and elimination of these activities, reserving the right to initiate legal action.</p>
        <p class="has-text-justified">In the event that any user or third party considers that there are facts or circumstances that reveal the unlawful nature of the use of any content and / or the performance of any activity on the web pages included or accessible through the website, you must send a notification to THE OWNER OF THE WEBSITE duly identifying themselves, specifying the alleged infringements.</p>

        <h3 class="title">9. PUBLICATIONS</h3>
        <p class="has-text-justified">The administrative information provided through the website does not replace the legal publicity of laws, regulations, plans, general provisions and acts that have to be formally published in the official journals of public administrations, which are the only instrument that attests to their authenticity and content. The information available on this website should be understood as a guide with no purpose of legal validity.</p>

        <h3 class="title">10. APPLICABLE LAW AND JURISDICTION</h3>
        <p>This Legal Notice is governed by Spanish law. The parties agree to submit to the jurisdiction of the Courts and Tribunals that correspond to them according to the applicable procedural legislation.</p>
  </section>
</template>

<script>
export default {
    name: 'LegalContentEn',
    props: ['data']

}
</script>

<style>

</style>
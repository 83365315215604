<template>
    <div class="card has-background-primary" style="height:100%;cursor:pointer;" @click="goToArea(area)">
        <div class="card-image">
            <figure class="image is-4by3">
             <img :src="area.imageUrl" :alt="area.imageAlt">
            </figure>
        </div>
        <div class="card-content">
            <div class="media">
            <div class="media-content" style="overflow-y:hidden">
                <p class="title is-4 has-text-primary-light">
                {{ area.name }}
                </p>
                <p class="subtitle is-6 has-text-primary-light">
                <span>{{ subtitle }}</span>                
                </p>                  
            </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AreaCard',
    props: ['area'],
    methods: {
        goToArea(area) {
            if(area.numberOfAuthors===0) return;
            this.$router.push(`/area/${area.moniker}`);
        }
    },
    computed: {
        subtitle: function() {
            
            if(this.area.numberOfAuthors === 0) {
                return this.$i18n.t('area.coming_soon');
            }
            if(this.area.numberOfAuthors === 1) {
                return `1 ${this.$i18n.t('author.singular')}`;
            }
            return `${this.area.numberOfAuthors} ${this.$i18n.t('author.plural')}`;
        }
    }
}
</script>
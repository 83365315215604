<template>
  <p>
    Search for the province, choose the author you would like to meet to immerse
    yourself in his or her creative universe. Request an appointment. You will
    receive an email to confirm your reservation. Easy?
  </p>
</template>

<script>
export default {
  name: "FaqHowContentEn",
};
</script>

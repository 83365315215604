<template>
  <main>
    <HomeHero />
    <section class="container pr-3 pl-3">
      <AreasCarousel :title="$t('home.areas.title')"/>
    </section>
    <section class="container pt-3 pr-3 pl-3">
      <FeaturedAuthorsCarousel />
    </section>
    <section class="container pt-3 pr-3 pl-3">
      <Faq />
    </section>
  </main>
</template>

<script>
// @ is an alias to /src
import HomeHero from '@/components/Home/HomeHero.vue';
import AreasCarousel from '@/components/Home/AreasCarousel.vue';
import FeaturedAuthorsCarousel from '@/components/Home/FeaturedAuthorsCarousel.vue';
import Faq from '@/components/Home/Faq.vue';

export default {
  name: 'HomeView',
  components: {
    HomeHero,
    AreasCarousel,
    FeaturedAuthorsCarousel,
    Faq
  }
}
</script>
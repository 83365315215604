<template>
  <section>
    <h1 class="title">{{ $t('legal.privacy') }}</h1>
        <h3 class="title">1. ¿Quién es el responsable de tratamiento de sus datos?</h3>
        <p>{{data.owner}}</p>
        <p>{{data.cif}}</p>
        <p>{{data.registeredOffice}}</p>
        <p>{{data.email}}</p>

        <h3 class="title">2. ¿Qué tipo de datos tenemos sobre su persona y cómo los hemos obtenido?</h3>
        <ol type="I">
          <li>Datos de identificación</li>
          <li>Direcciones postales o electrónicas</li>
          <li>Información comercial</li>
          <li>Imagen</li>
        </ol>
        <p class="has-text-justified">Todos los datos arriba mencionados los hemos obtenido o directamente de usted mediante la presentación de una oferta comercial, propuesta contractual, etc. o mediante su empresa al facilitarnos los datos de identificación y demás información necesaria para llevar al cabo el objeto de la relación contractual entre las partes. Será una obligación suya o de su empresa facilitarnos los datos actualizados en caso de modificación.</p>
        
        <h3 class="title">3. ¿Con qué finalidad tratamos sus datos?</h3>
        <p class="has-text-justified">Tratamos los datos que nos facilitan las personas interesadas con el fin de gestionar distintas actividades derivadas de procedimientos específicos realizados en materia de ventas, servicio post venta, gestión de proveedores, calidad de servicios, etc. De esta manera, utilizaremos sus datos para llevar a cabo alguna de las siguientes acciones:</p>
        <ol type="I">
          <li class="has-text-justified">Prestación de los servicios solicitados o entrega del producto adquirido.</li>
          <li class="has-text-justified">Envío de la información que nos soliciten mediante el formulario de contacto de nuestra página web o cualquier otro medio de contacto con nuestra empresa,</li>
          <li class="has-text-justified">Facilitar tanto a los clientes potenciales como a nuestros clientes, ofertas de productos y servicios de su interés,</li>
          <li class="has-text-justified">Llevar a cabo la gestión administrativa, fiscal y contable de nuestros clientes y/o proveedores,</li>
          <li class="has-text-justified">Llevar a cabo encuestas de satisfacción, estudios de mercado, etc. con el fin de poder ofrecerle las ofertas más adecuadas y una calidad optimizada de servicio, etc.</li>
          <li class="has-text-justified">Gestión laboral de empleados.</li>
        </ol>
        <p class="has-text-justified">Para llevar a cabo cualquiera de estas finalidades podrán utilizarse medios electrónicos como email, WhatsApp, etc.</p>

        <h3 class="title">4. ¿Por cuánto tiempo conservaremos sus datos?</h3>
        <p class="has-text-justified">Los datos personales relativos a personas físicas vinculadas a clientes potenciales, clientes y proveedores que recopilemos mediante los distintos formularios de contacto y/o recogida de información (incluidos los datos recabados a través de las cookies, si procediera) se conservarán mientras no se solicite su supresión por el interesado. Los datos proporcionados por nuestros clientes y proveedores se conservarán mientras se mantenga la relación mercantil entre las partes respetando en cualquier caso los plazos mínimos legales de conservación según la materia.</p>
        <p class="has-text-justified">Los datos de trabajadores se conservarán mientras se mantenga la relación laboral y en todo caso, según lo que prescriba la legislación labora al efecto.</p>
        <p class="has-text-justified">En el caso de las imágenes obtenidas por el sistema de videovigilancia, el plazo de conservación de las mismas será de 1 mes, de conformidad con lo establecido en el artículo 22.3 de la LOPDGDD, salvo que debieran ser conservados para su entrega a las autoridades competentes.</p>
        <p class="has-text-justified">En cualquier caso, guardaremos sus datos personales durante el período de tiempo que sea razonablemente necesario teniendo en cuenta nuestras necesidades de dar respuesta a cuestiones que se planteen o resolver problemas, realizar mejoras, activar nuevos servicios y cumplir los requisitos que exija la legislación aplicable. Esto significa que podemos conservar tus datos personales durante un período de tiempo razonable incluso después de que haya dejado de usar nuestros productos o de que haya dejado de usar esta página web. Después de este período, sus datos personales serán eliminados de todos nuestros sistemas.</p>

        <h3 class="title">5. ¿Cuál es la base de legitimación para el tratamiento de sus datos?</h3>
        <p class="has-text-justified">Según tipo de tratamiento de datos la base de legitimación es la siguiente:</p>
        
        <h5 class="subtitle">Gestión contable</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> gestión de facturación con clientes y/o proveedores. 
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Mantenimiento, desarrollo y control de la relación contractual entre las partes.
          </div>
        </div>

        <h5 class="subtitle">Gestión fiscal</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> aplicación de retenciones, bonificaciones, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Mantenimiento, desarrollo y control de la relación contractual entre las partes; Cumplimiento obligaciones legales.
          </div>
        </div>

        <h5 class="subtitle">Gestión administrativa</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> gestión de logística, almacén, entregas al cliente, recepción de mercancías, etc.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Mantenimiento, desarrollo y control de la relación contractual entre las partes.
          </div>
        </div>

        <h5 class="subtitle">Marketing</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> Acciones comerciales sobre nuestros productos o servicios dirigidas a nuestros clientes o aquellas personas que nos han solicitado información relativa a nuestros productos y servicios en el pasado, incluyendo la realización de encuestas de satisfacción a nuestros clientes.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Consentimiento libre e inequívoco del propio interesado (clientes potenciales), le hacemos constar que la retirada de este consentimiento en ningún caso puede condicionar la ejecución del contrato que hubiera entre las partes; interés legítimo de la compañía sobre la promoción y comercialización de productos o servicios similares a los obtenidos o solicitados por las personas interesadas en el pasado.
          </div>
        </div>

        <h5 class="subtitle">Si eres empleado, para la Gestión laboral</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> gestión administrativa, contable y fiscal de empleados.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Ejecución de un contrato laboral. Cumplimiento legal.
          </div>
        </div>

        <h5 class="subtitle">Gestión registro jornada laboral</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> hora de inicio y finalización de la jornada, así como horas extraordinarias.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Ejecución de un contrato laboral. Cumplimiento legal.
          </div>
        </div>

        <h5 class="subtitle">Imágenes</h5>
        <div class="columns">          
          <div class="column is-6">
            <span class="has-text-weight-bold">Tratamiento:</span> publicación de imagen/voz en web de la empresa, libros de la empresa, RRSS con el fin de dar a conocer a la plantilla.
          </div>
          <div class="column">
            <span class="has-text-weight-bold">Base de legitimación:</span> Consentimiento expreso del interesado.
          </div>
        </div>

        <p class="has-text-justified">En el supuesto de que no facilite sus datos de carácter personal no se podrá ejecutar su contrato, cumplir las obligaciones legales o derivadas de los poderes públicos.</p>

        <h3 class="title">6. ¿A qué destinatarios se comunicarán sus datos?</h3>
        <p class="has-text-justified">No cederemos sus datos personales a ninguna empresa tercera que pretenda utilizarlos en sus acciones de marketing directo, excepto en el caso de que nos haya autorizado expresamente a ello.</p>
        <p class="has-text-justified">Le informamos que podemos facilitar sus datos personales a organismos de la Administración Pública y Autoridades competentes en aquellos casos en que nos requieran legalmente o en los casos en que, actuando de buena fe, consideremos que tal acción es razonablemente necesaria para cumplir con un proceso judicial; para contestar cualquier reclamación o demanda jurídica; o para proteger los derechos de la empresa o sus clientes y el público en general.</p>
        <p class="has-text-justified">Le informamos que sus datos no van a ser cedidos o comunicados a terceros, la empresa es la única responsable para su tratamiento y custodia.</p>
        <p class="has-text-justified">Sí proporcionaremos sus datos personales a terceras personas (Ej. proveedores de servicios de Internet que nos ayudan a administrar nuestra página web o llevar a cabo los servicios contratados, empresas de soporte y mantenimiento informático, empresas de logística, gestorías y asesoramiento fiscal y contable, etc.). En cualquier caso, estas terceras personas deben mantener, en todo momento, los mismos niveles de seguridad que nosotros en relación a sus datos personales y, cuando sea necesario, estarán vinculadas por compromisos legales a fin de guardar sus datos personales de forma privada y segura, y también para utilizar únicamente la información siguiendo instrucciones específicas de la empresa.</p>

        <h3 class="title">7. ¿Transferencias de datos a terceros países?</h3>
        <p class="has-text-justified">No están previstas transferencias de datos a terceros países.</p>

        <h3 class="title">8. ¿Cuáles son sus derechos como interesado?</h3>
        <p class="has-text-justified">Cualquier persona tiene derecho a obtener confirmación sobre si estamos tratando datos personales que le conciernan, o no.</p>
        <p class="has-text-justified">En concreto, las personas interesadas pueden solicitar el derecho de acceso a sus datos personales, así como recibirlos en un formato común y lectura mecanizada si el tratamiento se efectúa por medios electrónicos (derecho de portabilidad).</p>
        <p class="has-text-justified">Asimismo, las personas interesadas pueden solicitar el derecho de rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que fueron recogidos.</p>
        <p class="has-text-justified">Complementariamente, en determinadas circunstancias, los interesados podrán solicitar la limitación del tratamiento de sus datos, o en determinadas circunstancias y por motivos relacionados con su situación particular, los interesados podrán ejercitar su derecho a oponerse al tratamiento de sus datos. Dejaremos de tratar los datos, salvo por motivos legítimos imperiosos, o el ejercicio o la defensa de posibles reclamaciones o en aquellas excepciones establecidas en la normativa aplicable.</p>
        <p class="has-text-justified">Asimismo, le informamos que tiene derecho a retirar sus consentimientos otorgados en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.</p>
        <p class="has-text-justified">Así mismo se informa al Usuario que en cualquier momento puede ejercitar los mencionados derechos dirigiéndose por escrito a nosotros utilizando los datos de contacto que aparecen en el punto 1, adjuntando copia de su DNI.</p>
        <p class="has-text-justified">Usted también tendrá el derecho a presentar una reclamación ante la Agencia Española de Protección de datos, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos.</p>
        <p class="has-text-centered">
          <a href="http://www.agpd.es/portalwebAGPD/index-ides-idphp.php" target="_blank">Agencia Española de Protección de datos</a></p>
        <p class="has-text-justified">Por otro lado, de acuerdo con lo dispuesto en la Ley 34/2002, de 11 de Julio, de Servicios de la Sociedad de Información y de Comercio Electrónico, nos comprometemos no enviar información comercial si usted así nos lo ha hecho saber.</p>

  </section>
</template>

<script>
export default {
    name: 'PrivacyContentEs',
    props: ['data']
}
</script>

<style>

</style>